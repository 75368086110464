export default {
  loading: "Cargando",
  next: "Siguiente",
  previous: "Anterior",
  close: "Cerrar",
  create: "Criar",
  cancel: "Cancelar",
  add: "Añadir",
  skip: "Omita este paso",
  selectOption: "Seleccione una opción",
  errors: {
    "required": "¡El campo es obligatorio!",
    "invalid": "¡El campo es inválido!",
    errorFields: {
      email: "E-mail",
      owner: {
        "identifier_str": "Documento",
        "fantasy_name": "Nombre fantasía"
      }
    },
    errorType: {
      "INVALID": "inválido",
      "undefined": "inválido",
      "BLANK": "vazio"
    }
  },
  countries: {
    AF: "Afganistán",
    AL: "Albania",
    DE: "Alemania",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    SA: "Arabia Saudí",
    DZ: "Argelia",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BD: "Bangladés",
    BB: "Barbados",
    BH: "Baréin",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BY: "Bielorrusia",
    BO: "Bolivia",
    BA: "Bosnia-Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunéi",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    BT: "Bután",
    CV: "Cabo Verde",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    IC: "Canarias",
    BQ: "Caribe neerlandés",
    QA: "Catar",
    EA: "Ceuta y Melilla",
    TD: "Chad",
    CZ: "Chequia",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    VA: "Ciudad del Vaticano",
    CO: "Colombia",
    KM: "Comoras",
    KP: "Corea del Norte",
    KR: "Corea del Sur",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croacia",
    CU: "Cuba",
    CW: "Curazao",
    DG: "Diego García",
    DK: "Dinamarca",
    DM: "Dominica",
    EC: "Ecuador",
    EG: "Egipto",
    SV: "El Salvador",
    AE: "Emiratos Árabes Unidos",
    ER: "Eritrea",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    ES: "España",
    US: "Estados Unidos",
    EE: "Estonia",
    ET: "Etiopía",
    EZ: "Eurozone",
    PH: "Filipinas",
    FI: "Finlandia",
    FJ: "Fiyi",
    FR: "Francia",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    GH: "Ghana",
    GI: "Gibraltar",
    GD: "Granada",
    GR: "Grecia",
    GL: "Groenlandia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GF: "Guayana Francesa",
    GG: "Guernesey",
    GN: "Guinea",
    GQ: "Guinea Ecuatorial",
    GW: "Guinea-Bisáu",
    GY: "Guyana",
    HT: "Haití",
    HN: "Honduras",
    HM: "Islas Heard y McDonald",
    HU: "Hungría",
    IN: "India",
    ID: "Indonesia",
    IQ: "Irak",
    IR: "Irán",
    IE: "Irlanda",
    BV: "Isla Bouvet",
    AC: "Isla de la Ascensión",
    IM: "Isla de Man",
    CX: "Isla de Navidad",
    NF: "Isla Norfolk",
    IS: "Islandia",
    AX: "Islas Åland",
    KY: "Islas Caimán",
    CC: "Islas Cocos",
    CK: "Islas Cook",
    FO: "Islas Feroe",
    GS: "Islas Georgia del Sur y Sandwich del Sur",
    FK: "Islas Malvinas",
    MP: "Islas Marianas del Norte",
    MH: "Islas Marshall",
    UM: "Islas menores alejadas de EE. UU.",
    PN: "Islas Pitcairn",
    SB: "Islas Salomón",
    TC: "Islas Turcas y Caicos",
    VG: "Islas Vírgenes Británicas",
    VI: "Islas Vírgenes de EE. UU.",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajistán",
    KE: "Kenia",
    KG: "Kirguistán",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesoto",
    LV: "Letonia",
    LB: "Líbano",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malasia",
    MW: "Malaui",
    MV: "Maldivas",
    ML: "Mali",
    MT: "Malta",
    MA: "Marruecos",
    MQ: "Martinica",
    MU: "Mauricio",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "México",
    FM: "Micronesia",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar (Birmania)",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelanda",
    OM: "Omán",
    NL: "Países Bajos",
    PK: "Pakistán",
    PW: "Palaos",
    PA: "Panamá",
    PG: "Papúa Nueva Guinea",
    PY: "Paraguay",
    PE: "Perú",
    PF: "Polinesia Francesa",
    PL: "Polonia",
    PT: "Portugal",
    PR: "Puerto Rico",
    HK: "RAE de Hong Kong (China)",
    MO: "RAE de Macao (China)",
    GB: "Reino Unido",
    CF: "República Centroafricana",
    CG: "República del Congo",
    CD: "República Democrática del Congo",
    DO: "República Dominicana",
    RE: "Reunión",
    RW: "Ruanda",
    RO: "Rumanía",
    RU: "Rusia",
    EH: "Sáhara Occidental",
    WS: "Samoa",
    AS: "Samoa Americana",
    BL: "San Bartolomé",
    KN: "San Cristóbal y Nieves",
    SM: "San Marino",
    MF: "San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    SH: "Santa Elena",
    LC: "Santa Lucía",
    ST: "Santo Tomé y Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SX: "Sint Maarten",
    SY: "Siria",
    SO: "Somalia",
    LK: "Sri Lanka",
    SZ: "Suazilandia",
    ZA: "Sudáfrica",
    SD: "Sudán",
    SS: "Sudán del Sur",
    SE: "Suecia",
    CH: "Suiza",
    SR: "Surinam",
    SJ: "Svalbard y Jan Mayen",
    TH: "Tailandia",
    TW: "Taiwán",
    TZ: "Tanzania",
    TJ: "Tayikistán",
    IO: "Territorio Británico del Océano Índico",
    TF: "Territorios Australes Franceses",
    PS: "Territorios Palestinos",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TA: "Tristán de Acuña",
    TN: "Túnez",
    TM: "Turkmenistán",
    TR: "Turquía",
    TV: "Tuvalu",
    UA: "Ucrania",
    UG: "Uganda",
    UN: "United Nations",
    UY: "Uruguay",
    UZ: "Uzbekistán",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis y Futuna",
    YE: "Yemen",
    DJ: "Yibuti",
    ZM: "Zambia",
    ZW: "Zimbabue"
  }
};
