<template>
  <span v-if="blocked || pastDue" class="ui-tag ui-tag-warning">
    <span>
      <i class="fas fa-fw fa-exclamation-circle" />

      <span v-if="blocked && pastDue">
        {{ $t("components.customers-and-subscriptions.subscriptions.badges.blocked-pastdue.blocked-and-past-due") }}
      </span>
      <span v-if="blocked && !pastDue">
        {{ $t("components.customers-and-subscriptions.subscriptions.badges.blocked-pastdue.blocked") }}
      </span>
      <span v-if="pastDue && !blocked">
        {{ $t("components.customers-and-subscriptions.subscriptions.badges.blocked-pastdue.past-due") }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    blocked: {
      default: false,
      type: Boolean
    },
    pastDue: {
      default: false,
      type: Boolean
    }
  }
};
</script>
