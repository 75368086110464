import en from "./enums/en";
import es from "./enums/es";
import ptbr from "./enums/pt-BR";

export default {
  en: {
    enums: en
  },
  es: {
    enums: es
  },
  "pt-BR": {
    enums: ptbr
  }
};
