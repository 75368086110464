const Utils = {
  getQueryStringByName: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  windowReload: function () {
    return window.location.reload();
  },
  setCookie: function (name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + date.toGMTString();
  },
  getCookie: function (name) {
    const cookieMatch = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return cookieMatch ? cookieMatch[2] : null;
  },
  deleteCookie: function (name) {
    this.setCookie(name, "", -1);
  },

  // String functions
  toKebabCase: function (string) {
    return string.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
      .replace(/[\s_]+/g, "-") // replace all spaces and low dash
      .toLowerCase(); // convert to lower case
  },

  // Object functions
  isObject: function (item) {
    return (item && typeof item === "object" && !Array.isArray(item));
  },
  deepMerge: function (target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {}});
          this.deepMerge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.deepMerge(target, ...sources);
  }
};

export default Utils;
