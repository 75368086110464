export default {
  payment: {
    methods: {
      cash: "Dinheiro",
      check: "Cheque",
      boleto: "Boleto bancário",
      "bank-transfer": "Transferência bancária",
      "credit-card": "Cartão de crédito",
      paypal: "Paypal",
      moip: "Moip",
      dda: "DDA",
      oxxo: "OXXO",
      baloto: "Baloto",
      others: "Outros meios"
    }
  }
};
