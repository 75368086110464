<template>
  <div class="component-datepicker">
    <v-date-picker
      mode="single"
      :min-date="minDateParsed"
      :max-date="maxDateParsed"
      v-model="selectedDate">
      <div slot-scope="props">
        <input type="text"
               :required="isRequired"
               class="form-control date-mask-default"
               placeholder="00/00/0000"
               :value="props.inputValue"
               @change="reflectDateChangeOnDatePicker">
      </div>
    </v-date-picker>

    <input type="hidden"
           :value="dateFormatedIso8601"
           :name="name"
           :required="isRequired">
  </div>
</template>

<script>
import I18n from "core/i18n";
import coreDate from "core/date";

export default {
  props: {
    isRequired: {
      default: null,
      type: Boolean
    },
    name: {
      default: null,
      type: String
    },
    date: {
      default: null,
      type: String
    },
    minDate: {
      default: null,
      type: String
    },
    maxDate: {
      default: null,
      type: String
    }
  },

  data: function () {
    // https://stackoverflow.com/questions/4310953/invalid-date-in-safari#5646753
    const _adaptedDateCrossBrowsers = this.date ? this.date.replace(/-/g, "/") : null;
    return {
      dateScope: {},
      selectedDate: this.date ? new Date(`${_adaptedDateCrossBrowsers} 00:00:00`) : null,
      dateFormatedByLocale: this._formatDateByLocale(this.selectedDate),
      dateValue: this.selectedDate,
      inputValueChanged: false,
      minDateParsed: this.minDate ? new Date(`${_adaptedDateCrossBrowsers} 00:00:00`) : null,
      maxDateParsed: this.maxDate ? new Date(`${_adaptedDateCrossBrowsers} 00:00:00`) : null
    };
  },

  methods: {
    reflectDateChangeOnDatePicker (event) {
      const value = event.srcElement.value;
      this.selectedDate = coreDate.parseStringToDate(I18n.locale, value);
    },

    _formatDateByLocale (date) {
      if (date) return this.$d(date, "default");
      return null;
    }
  },

  watch: {
    selectedDate (newValue) {
      this.$emit("dateChanged", newValue);
    }
  },

  computed: {
    dateFormatedIso8601 () {
      if (!this.selectedDate) return null;

      const year = this.selectedDate.getUTCFullYear();
      const month = this.selectedDate.getUTCMonth() + 1;
      const day = this.selectedDate.getUTCDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  }
};
</script>
