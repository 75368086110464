<template>
  <div>
    <div v-if="loading">
      <action-loading />
    </div>

    <div v-if="!loading">
      <div class="subscription-divider d-flex half-gutters">
        <div class="col-xs-10">
          <h5 class="subtitle addons-title">
            {{ $t("components.customers-and-subscriptions.subscriptions.details-sidebar.addons.title") }}
          </h5>
        </div>
      </div>

      <div class="subscription-item-addon"
           v-for="addon in addons"
           :key="addon.id">
        <div class="col-xs-12 subscription-name-labels">
          <label class="d-block">

            <subscriptions-badges-status :status="addon.status"
                                         :created-at="addon.created_at"
                                         :activated-at="addon.activated_at"
                                         :canceled-at="addon.canceled_at"
                                         :ended-at="addon.ended_at" />

            {{ addon.sale_item.name }}

          </label>
          <span class="ui-tag-wrapper">

            <subscriptions-badges-id :public-id="addon.id" />

            <subscriptions-badges-amount :sale-item-price="addon.sale_item_price"
                                         :readjusted-amount="addon.amount"
                                         :amount-considering-coupon="addon.amount_considering_coupon"
                                         :product-type="addon.sale_item.type"
                                         :currency="addon.currency" />

            <subscriptions-badges-discount v-if="addon.coupon"
                                           :kind="addon.coupon.kind"
                                           :amount="addon.coupon.amount"
                                           :billing-unit="addon.coupon.billing_unit"
                                           :billing-quantity-type="addon.coupon.billing_quantity_type"
                                           :billing-quantity-total="addon.coupon.billing_quantity_total"
                                           :billing-quantity-used="addon.coupon.billing_quantity_used" />

          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIRoutes from "core/api-routes";
import ComponentActionLoading from "core/components/action-loading";
import ComponentSubscriptionsBadgesId from "domains/customers-and-subscriptions/components/subscriptions/badges/id";
import ComponentSubscriptionsBadgesAmount from "domains/customers-and-subscriptions/components/subscriptions/badges/amount";
import ComponentSubscriptionsBadgesDiscount from "domains/customers-and-subscriptions/components/subscriptions/badges/discount";
import ComponentSubscriptionsBadgesExpirationDay from "domains/customers-and-subscriptions/components/subscriptions/badges/expiration-day";
import ComponentSubscriptionsBadgesStatus from "domains/customers-and-subscriptions/components/subscriptions/badges/status";

export default {
  props: {
    customerId: {
      default: null,
      type: String
    },
    parentSubscriptionId: {
      default: null,
      type: String
    }
  },

  created () {
    const self = this;
    this.loading = true;

    const url = APIRoutes.paths.v1.subscriptions.addons.index(this.customerId, this.parentSubscriptionId);

    return this.$http.get(url).then(function (response) {
      self.addons = response.body["subscription/addons"];
      self.loading = false;
    }, function () {
      self.loading = false;
    });
  },

  data () {
    return {
      addons: [],
      loading: true
    };
  },

  components: {
    "subscriptions-badges-id": ComponentSubscriptionsBadgesId,
    "subscriptions-badges-amount": ComponentSubscriptionsBadgesAmount,
    "subscriptions-badges-discount": ComponentSubscriptionsBadgesDiscount,
    "subscriptions-badges-expiration-day": ComponentSubscriptionsBadgesExpirationDay,
    "subscriptions-badges-status": ComponentSubscriptionsBadgesStatus,
    "action-loading": ComponentActionLoading
  }
};
</script>
