/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag "application" %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from "vue";
import VueResource from "vue-resource";

Vue.use(VueResource);

Vue.http.interceptors.push(function (request) {
  const token = document.querySelector("[name='csrf-token']").getAttribute("content");
  request.headers.set("X-CSRF-TOKEN", token);
});

// Styles
import "../styles/application";

// Core
import "core/init";
import "core/store";

// Event History
import "event-history/init";

// Domains
import "domains/customers-and-subscriptions/init";
