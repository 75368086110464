<template>
  <span class="ui-dropdown">
    <a href="javascript:void(0)"
       class="ui-dropdown-item-ellipsis"
       data-toggle="dropdown">
      <i class="fa-fw fas fa-ellipsis-v"/>
    </a>
    <ul class="ui-dropdown-menu ui-dropdown-menu-right" role="menu">
      <li role="presentation">
        <a class="ui-dropdown-menu-item" :href="routes.goToCustomer">
          {{ $t("components.customers-and-subscriptions.customers.item.menu.customer") }}
        </a>
      </li>
      <li role="presentation">
        <a class="ui-dropdown-menu-item" :href="routes.goToSubscriptions">
          {{ $t("components.customers-and-subscriptions.customers.item.menu.subscriptions") }}
        </a>
      </li>
      <li role="presentation">
        <a class="ui-dropdown-menu-item" :href="routes.goToInvoices">
          {{ $t("components.customers-and-subscriptions.customers.item.menu.invoices") }}
        </a>
      </li>
      <li role="presentation">
        <a class="ui-dropdown-menu-item" :href="routes.goToLogs">
          {{ $t("components.customers-and-subscriptions.customers.item.menu.logs") }}
        </a>
      </li>
      <li role="presentation" v-if="showCustomerMenuProfile">
        <a class="ui-dropdown-menu-item" :href="routes.goToDocuments">
          {{ $t("components.customers-and-subscriptions.customers.item.menu.documents") }}
        </a>
      </li>
      <li role="presentation">
        <a class="ui-dropdown-menu-item" :href="routes.goToProfile">
          {{ $t("components.customers-and-subscriptions.customers.item.menu.profile") }}
        </a>
      </li>
    </ul>
  </span>
</template>

<script>
import Routes from "core/legacy-rails-routes.js";

export default {
  props: {
    customerInternalId: {
      default: null,
      type: Number
    },
    customerEntityId: {
      default: null,
      type: String
    },
    showCustomerMenuProfile: {
      required: true,
      type: Boolean
    },
  },

  data: function () {
    return {
      routes: {
        goToCustomer: Routes.paths.customers.show(this.customerInternalId),
        goToDocuments: Routes.paths.customers.documents.index(this.customerInternalId),
        goToSubscriptions: Routes.paths.customers.subscriptions.index(this.customerInternalId),
        goToInvoices: Routes.paths.customers.invoices.index(this.customerInternalId),
        goToLogs: Routes.paths.customers.logs.index(this.customerInternalId),
        goToProfile: Routes.paths.customers.profile.index(this.customerInternalId)
      }
    };
  }
};
</script>
