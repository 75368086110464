<template>
  <span
    class="subscriptions-badges-amount ui-tag ui-tag-primary"
    data-toggle="popover"
    data-html="true"
    data-trigger="hover"
    data-placement="top"
    data-container="body"
    :data-title=popoverTitle
    :data-content=popoverContent
  >
    <span>
      <i :class="iconClass"/>
      <money-format
        class="money-format"
        :value="amountConsideringCoupon"
        :locale="locale"
        :currency-code="currency"
        :subunit-value=false
        :supplemental-precision="supplementalPrecision"
      />
    </span>
  </span>
</template>

<script>
import MoneyFormat from "vue-money-format";
import I18n from "core/i18n";

export default {
  components: {
    "money-format": MoneyFormat
  },
  props: {
    saleItemPrice: {
      default: null,
      type: Number
    },
    readjustedAmount: {
      default: null,
      type: Number
    },
    amountConsideringCoupon: {
      default: null,
      type: Number
    },
    productType: {
      default: "",
      type: String
    },
    currency: {
      default: "",
      type: String
    }
  },
  data: function () {
    const supplementalPrecision = (["MXN", "BRL", "USD"].indexOf(this.currency) > -1) ? 0 : 2;
    const formatMoney = function (amount, currency) {
      return MoneyFormat.methods.formatMoney(amount, I18n.locale, currency, false, 1, false, supplementalPrecision);
    };
    const i18nScope = "components.customers-and-subscriptions.subscriptions.badges.amount";
    const saleItemPriceLabel = I18n.t(`${i18nScope}.labels.sale-item-price`);
    const readjustedAmountLabel = I18n.t(`${i18nScope}.labels.price-readjusted`);
    const amountConsideringCouponLabel = I18n.t(`${i18nScope}.labels.amount-considering-coupon`);
    const productTypeValue = I18n.t(`${i18nScope}.product-type.${this.productType}`);

    const formattedSaleItemPrice = formatMoney(this.saleItemPrice, this.currency);
    const formattedReadjustedAmountLabel = formatMoney(this.readjustedAmount, this.currency);
    const formattedAmountWithDiscount = formatMoney(this.amountConsideringCoupon, this.currency);
    let popoverContent = `${saleItemPriceLabel}: ${formattedSaleItemPrice}`
      + `<br/> ${readjustedAmountLabel}: ${formattedReadjustedAmountLabel}`
      + `<br/> ${amountConsideringCouponLabel}: ${formattedAmountWithDiscount}`
    ;
    return {
      popoverContent: popoverContent,
      popoverTitle: productTypeValue,
      iconClass: (this.productType === "recurrent" ? "fas fa-fw fa-sync-alt" : "fas fa-fw fa-arrow-circle-right"),
      locale: I18n.locale,
      supplementalPrecision: supplementalPrecision
    };
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".subscriptions-badges-amount[data-toggle=\"popover\"]").popover();
    } catch (error) {
      // console.log(error);
    }
  }
};

</script>

<style scoped lang="scss">
.money-format {
  display: inline;
}
</style>
