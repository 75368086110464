export default {
  payment: {
    methods: {
      cash: "Dinero",
      check: "Cheque",
      boleto: "Boleto brasileño",
      "bank-transfer": "Transferencia bancaria",
      "credit-card": "Tarjeta de crédito",
      paypal: "Paypal",
      moip: "Moip",
      dda: "DDA",
      oxxo: "OXXO",
      baloto: "Baloto",
      others: "Otros medios"
    }
  }
};
