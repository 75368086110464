export default {
  loading: "Carregando",
  next: "Próximo",
  previous: "Anterior",
  close: "Fechar",
  create: "Criar",
  cancel: "Cancelar",
  add: "Adicionar",
  skip: "Pular esta etapa",
  selectOption: "Selecione uma opção",
  errors: {
    "required": "O campo é obrigatório!",
    "invalid": "O campo é inválido!",
    errorFields: {
      email: "E-mail",
      owner: {
        "identifier_str": "Documento",
        "fantasy_name": "Nome Fantasia"
      }
    },
    errorType: {
      "INVALID": "inválido",
      "undefined": "inválido",
      "BLANK": "vazio"
    }
  },
  countries: {
    AF: "Afeganistão",
    ZA: "África do Sul",
    AL: "Albânia",
    DE: "Alemanha",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antártida",
    AG: "Antígua e Barbuda",
    SA: "Arábia Saudita",
    DZ: "Argélia",
    AR: "Argentina",
    AM: "Armênia",
    AW: "Aruba",
    AU: "Austrália",
    AT: "Áustria",
    AZ: "Azerbaijão",
    BS: "Bahamas",
    BH: "Bahrein",
    BD: "Bangladesh",
    BB: "Barbados",
    BE: "Bélgica",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermudas",
    BY: "Bielorrússia",
    BO: "Bolívia",
    BA: "Bósnia e Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunei",
    BG: "Bulgária",
    BF: "Burquina Faso",
    BI: "Burundi",
    BT: "Butão",
    CV: "Cabo Verde",
    KH: "Camboja",
    CA: "Canadá",
    QA: "Catar",
    KZ: "Cazaquistão",
    EA: "Ceuta e Melilha",
    TD: "Chade",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    VA: "Cidade do Vaticano",
    SG: "Cingapura",
    CO: "Colômbia",
    KM: "Comores",
    CG: "Congo - Brazzaville",
    CD: "Congo - Kinshasa",
    KP: "Coreia do Norte",
    KR: "Coreia do Sul",
    CI: "Costa do Marfim",
    CR: "Costa Rica",
    HR: "Croácia",
    CU: "Cuba",
    CW: "Curaçao",
    DG: "Diego Garcia",
    DK: "Dinamarca",
    DJ: "Djibuti",
    DM: "Dominica",
    EG: "Egito",
    SV: "El Salvador",
    AE: "Emirados Árabes Unidos",
    EC: "Equador",
    ER: "Eritreia",
    SK: "Eslováquia",
    SI: "Eslovênia",
    ES: "Espanha",
    US: "Estados Unidos",
    EE: "Estônia",
    ET: "Etiópia",
    EZ: "Eurozone",
    FJ: "Fiji",
    PH: "Filipinas",
    FI: "Finlândia",
    FR: "França",
    GA: "Gabão",
    GM: "Gâmbia",
    GH: "Gana",
    GE: "Geórgia",
    GI: "Gibraltar",
    GD: "Granada",
    GR: "Grécia",
    GL: "Groenlândia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GY: "Guiana",
    GF: "Guiana Francesa",
    GN: "Guiné",
    GQ: "Guiné Equatorial",
    GW: "Guiné-Bissau",
    HT: "Haiti",
    NL: "Holanda",
    HN: "Honduras",
    BV: "Ilha Bouvet",
    HM: "Ilha Heard e Ilhas McDonald",
    HK: "Hong Kong, RAE da China",
    HU: "Hungria",
    YE: "Iêmen",
    CX: "Ilha Christmas",
    AC: "Ilha de Ascensão",
    IM: "Ilha de Man",
    NF: "Ilha Norfolk",
    AX: "Ilhas Åland",
    IC: "Ilhas Canárias",
    KY: "Ilhas Cayman",
    CC: "Ilhas Cocos (Keeling)",
    CK: "Ilhas Cook",
    FO: "Ilhas Faroe",
    GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
    FK: "Ilhas Malvinas",
    MP: "Ilhas Marianas do Norte",
    MH: "Ilhas Marshall",
    UM: "Ilhas Menores Distantes dos EUA",
    PN: "Ilhas Pitcairn",
    SB: "Ilhas Salomão",
    TC: "Ilhas Turks e Caicos",
    VG: "Ilhas Virgens Britânicas",
    VI: "Ilhas Virgens dos EUA",
    IN: "Índia",
    ID: "Indonésia",
    IR: "Irã",
    IQ: "Iraque",
    IE: "Irlanda",
    IS: "Islândia",
    IL: "Israel",
    IT: "Itália",
    JM: "Jamaica",
    JP: "Japão",
    JE: "Jersey",
    JO: "Jordânia",
    XK: "Kosovo",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesoto",
    LV: "Letônia",
    LB: "Líbano",
    LR: "Libéria",
    LY: "Líbia",
    LI: "Liechtenstein",
    LT: "Lituânia",
    LU: "Luxemburgo",
    MO: "Macau, RAE da China",
    MK: "Macedônia",
    MG: "Madagascar",
    MY: "Malásia",
    MW: "Malawi",
    MV: "Maldivas",
    ML: "Mali",
    MT: "Malta",
    MA: "Marrocos",
    MQ: "Martinica",
    MU: "Maurício",
    MR: "Mauritânia",
    YT: "Mayotte",
    MX: "México",
    MM: "Mianmar (Birmânia)",
    FM: "Micronésia",
    MZ: "Moçambique",
    MD: "Moldávia",
    MC: "Mônaco",
    MN: "Mongólia",
    ME: "Montenegro",
    MS: "Montserrat",
    UN: "Nações Unidas",
    NA: "Namíbia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicarágua",
    NE: "Níger",
    NG: "Nigéria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nova Caledônia",
    NZ: "Nova Zelândia",
    OM: "Omã",
    BQ: "Países Baixos Caribenhos",
    PW: "Palau",
    PA: "Panamá",
    PG: "Papua-Nova Guiné",
    PK: "Paquistão",
    PY: "Paraguai",
    PE: "Peru",
    PF: "Polinésia Francesa",
    PL: "Polônia",
    PR: "Porto Rico",
    PT: "Portugal",
    KE: "Quênia",
    KG: "Quirguistão",
    KI: "Quiribati",
    GB: "Reino Unido",
    CF: "República Centro-Africana",
    DO: "República Dominicana",
    CM: "República dos Camarões",
    RE: "Reunião",
    RO: "Romênia",
    RW: "Ruanda",
    RU: "Rússia",
    EH: "Saara Ocidental",
    PM: "Saint Pierre e Miquelon",
    WS: "Samoa",
    AS: "Samoa Americana",
    SM: "San Marino",
    SH: "Santa Helena",
    LC: "Santa Lúcia",
    BL: "São Bartolomeu",
    KN: "São Cristóvão e Nevis",
    MF: "São Martinho",
    ST: "São Tomé e Príncipe",
    VC: "São Vicente e Granadinas",
    SC: "Seicheles",
    SN: "Senegal",
    SL: "Serra Leoa",
    RS: "Sérvia",
    SX: "Sint Maarten",
    SY: "Síria",
    SO: "Somália",
    LK: "Sri Lanka",
    SZ: "Suazilândia",
    SD: "Sudão",
    SS: "Sudão do Sul",
    SE: "Suécia",
    CH: "Suíça",
    SR: "Suriname",
    SJ: "Svalbard e Jan Mayen",
    TH: "Tailândia",
    TW: "Taiwan",
    TJ: "Tajiquistão",
    TZ: "Tanzânia",
    CZ: "Tchéquia",
    IO: "Território Britânico do Oceano Índico",
    TF: "Territórios Franceses do Sul",
    PS: "Territórios palestinos",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad e Tobago",
    TA: "Tristão da Cunha",
    TN: "Tunísia",
    TM: "Turcomenistão",
    TR: "Turquia",
    TV: "Tuvalu",
    UA: "Ucrânia",
    UG: "Uganda",
    UY: "Uruguai",
    UZ: "Uzbequistão",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnã",
    WF: "Wallis e Futuna",
    ZM: "Zâmbia",
    ZW: "Zimbabue"
  }
};
