<template>
  <span
    class="subscriptions-badges-payment-method ui-tag ui-tag-primary"
    data-toggle="popover"
    data-trigger="hover"
    data-placement="top"
    data-container="body"
    :data-content="popoverContent">
    <i :class="iconClass" />
  </span>
</template>

<script>
import I18n from "core/i18n";
import Utils from "core/utils";

export default {
  props: {
    paymentMethod: {
      default: null,
      type: String
    }
  },

  data: function () {
    const translatedPaymentMethod = I18n.t(`enums.payment.methods.${Utils.toKebabCase(this.paymentMethod)}`);
    const i18nScope = "components.customers-and-subscriptions.subscriptions.badges.payment-method";
    const paymentMethodLabel = I18n.t(`${i18nScope}.labels.paid-with`, { paymentMethod: translatedPaymentMethod });

    let iconClass = "fas fa-fw";
    if (this.paymentMethod === "credit_card") {
      iconClass = `${iconClass} fa-credit-card`;
    } else if (["boleto", "oxxo", "baloto"].includes(this.paymentMethod)) {
      iconClass = `${iconClass} fa-barcode`;
    } else {
      iconClass = `${iconClass} fa-shopping-cart`;
    }

    return {
      popoverContent: paymentMethodLabel,
      iconClass: iconClass
    };
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".subscriptions-badges-payment-method[data-toggle=\"popover\"]").popover();
    } catch (error) {
      // console.log(error);
    }
  }
};
</script>
