import Vue from "vue";
import I18n from "core/i18n";
import Store from "core/store";

const components = [
  "log",
  "logs-header"
];

document.addEventListener("DOMContentLoaded", () => {
  components.forEach(function (component) {
    const tagName = `event-history-${component.replace(/\//g, "-")}`;
    const elements = document.getElementsByTagName(tagName);

    // Do not initialize if element was not rendered
    if (elements.length) {
      var elementsList = Array.prototype.slice.call(elements);

      elementsList.forEach(function (element) {
        const data = element.getAttribute("data");
        const importedComponent = require(`./components/${component}`);

        // Get props from server side rendered data attribute
        let props = {};
        if (data) props = JSON.parse(data);

        new Vue({
          i18n: I18n,
          store: Store,
          el: element,
          render: h => h(importedComponent.default, { props })
        });
      });
    }
  });
});
