import Vue from "vue";
import VueI18n from "vue-i18n";
import dateTimeFormats from "../translations/date-time-formats";
import ComponentsTranslations from "../translations/components";
import EnumsTranslations from "../translations/enums";
import TextTranslations from "../translations/text";
import Utils from "./utils";

Vue.use(VueI18n);

export default new VueI18n({
  locale: Utils.getQueryStringByName("locale") || Utils.getCookie("locale") || "pt-BR",
  // Merge all translation contexts
  messages: Utils.deepMerge(ComponentsTranslations, EnumsTranslations, TextTranslations),
  dateTimeFormats
});
