export default {
  core: {
    "communication-in-app": {
      hello: "Hello <strong>{userName}.</strong>",
      "click-text": "Click here to view.",
      "last-step-first-text": "Any questions or problems are available on the channel <strong>#duvidas-billing</strong> on Slack",
      "last-step-last-text": "Thank you, <strong>Commandos Team</strong>",
      "title-rated": "Was this communication helpful to you?"
    },
    "history-comment": {
      "from": "From",
      "to": "To",
      "text": "Simple text",
      "reason": "<strong>Reason:</strong> {reason}"
    }
  },

  "event-history": {
    "created_at": "Created at",
    author: "Author",
    action: "Action",
    information: "Informations",
    "more_information": "Show more information",
    entities: {
      "customer_relationship": {
        customer: "Customer",
        "customer_contact": "Customer contact"
      },
      billing: {
        subscription: "Billing settings - Subscription",
        invoice: "Invoice",
        "invoice_item": "Invoice item"
      },
      "subscription_management": {
        subscription: "Subscription",
        "subscription_attendant": "Subscription attendant"
      },
      taxes: {
        customer: "Taxes settings - Cliente",
        subscription: "Taxes settings - Subscription"
      },
      payments: {
        subscription: "Payment settings - Subscription",
        charge: "Charge",
        "charge_group": "Charges",
        "payment_intent": "Payment Intent"
      },
      legacy: {
        "finance_entity": "Customer",
        account: "Customer",
        "billing_subscription_item": "Subscription",
        "billing_invoice": "Invoice/Charge",
        "billing_invoice_item": "Invoice/Charge item"
      }
    },
    subject: {
      customer: "Customer",
      "subscription_attendant": "Subscription attendant",
      subscription: "Subscription",
      "invoice_item": "Invoice item",
      charge: "Charge",
      invoice: "Invoice"
    },
    "event-type": {
      activation: "Activation",
      create: "Create",
      update: "Update",
      comment: "Comentario",
      event: "Event"
    },
    "from": "From",
    "to": "To",
    "text": "Simple text"
  },

  "customers-and-subscriptions": {
    customers: {
      actions: {
        "integrations-warning": "Warning: customers added manually are not guaranteed for integration with other systems.",
        "box-error": {
          label: "Could not add customer <strong class='customer-name'>{CustomerName}</strong>",
          error: "The field <span class='error-field-name'><strong>{field}</strong></span> is <span class='error-field-type'><strong>{type}</strong></span>"
        },
        "box-success": {
          label: "The customer <strong>{CustomerName}</strong> was added successfully",
          "create-another-customer": "Click here to add another Customer",
          "back-to-home": "Back to Home"
        },
        new: {
          "main-title": "Add new customer",
          "fill-fields": "All fields are required.",
          "individual-person": "Individual person",
          "legal-entity": "Legal entity"
        },
        "form-new-customer": {
          "indentify-str": "Document (CPF)",
          "indentify-str-entity": "Document (CNPJ)",
          "full-name": "Full name",
          "entity-name": "Fantasy name",
          "legal-name": "Company Name",
          "phone": "Phone",
          "zipcode": "Zipcode",
          "address": "Address",
          "district": "District",
          "address-number": "Address number",
          "address-plus": "Full address",
          "country": "Country",
          "state": "State",
          "city": "City"
        },
        contact: {
          title: "Add Contact",
          "first_name": "Name",
          "last_name": "Last name",
          email: "Email",
          phone: "Phone",
          kind: {
            title: "Type",
            financial: "Financial",
            marketing: "Marketing",
            president: "CEO / Owner",
            director: "Director",
            admin: "Administrator",
            sales: "Salesman"
          }
        }
      },
      badges: {
        id: {
          "customer-id": "Customer ID"
        }
      },
      item: {
        menu: {
          "subscriptions": "Subscriptions",
          "invoices": "Invoices and Charges",
          "logs": "History",
          "customer": "Customer Profile",
          "profile": "Billing Dashboard",
          "documents": "Documents"
        }
      }
    },
    subscriptions: {
      badges: {
        amount: {
          "product-type": {
            recurrent: "Recurrent value",
            "non-recurrent": "One time value"
          },
          labels: {
            "sale-item-price": "Plan price",
            "price-readjusted": "Readjusted price",
            "amount-considering-coupon": "Amount with discounts"
          }
        },
        "blocked-pastdue": {
          blocked: "Blocked",
          "past-due": "Past due",
          "blocked-and-past-due": "Past due and blocked"
        },
        discount: {
          "billing-quantity-type": {
            "unlimited": "Unlimited",
            "limited": "Limited"
          },
          labels: {
            "billing-quantity-type": "Usage time",
            discount: "{amountWithSymbol} OFF"
          },
          "uses-left": "Remaining usage time: {couponUsesLeft} months"
        },
        "expiration-day": {
          labels: {
            "expiration-day": "Fixed expiration always on {expirationDay} of each month",
            "payments-net-term-days": "Expiration {paymentsNetTermDays} days after the invoice generation date",
            "dont-use-payments-net-term-days": "Day",
            "use-payments-net-term-days": "Generate +",
            day: "Day"
          }
        },
        "payment-method": {
          labels: {
            "paid-with": "Paid with {paymentMethod}"
          }
        },
        status: {
          title: {
            trial: "Trial since:",
            active: "Active since:",
            canceled: "Canceled since:",
            ended: "Ended since:"
          }
        },
        "upgrade-invoice": {
          label: "Generate upgrade/upsell invoice"
        },
        id: {
          "subscription-id": "Subscription ID"
        }
      },
      "btn-open-details-sidebar": {
        text: "<strong>Click here</strong> to see this addons from this item."
      },
      "details-sidebar": {
        "view-all-subscriptions": "View all subscriptions from this customer",
        addons: {
          title: "Addons"
        }
      }
    }
  }
};
