import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

import customersAndSubscriptions from "domains/customers-and-subscriptions/store/init";

export default new Vuex.Store({
  modules: {
    customersAndSubscriptions
  }
});
