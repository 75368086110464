<template>
  <span
    class="subscriptions-badges-expiration-day ui-tag ui-tag-help"
    data-toggle="popover"
    data-trigger="hover"
    data-placement="top"
    data-container="body"
    :data-content="popoverContent">
    {{ $t("components.customers-and-subscriptions.subscriptions.badges.expiration-day.labels." + (usePaymentsNetTermDays ? "use-payments-net-term-days" : "dont-use-payments-net-term-days")) }}
    {{ usePaymentsNetTermDays ? paymentsNetTermDays : expirationDay }}
  </span>
</template>

<script>
import I18n from "core/i18n";

export default {
  props: {
    expirationDay: {
      default: null,
      type: Number
    },
    usePaymentsNetTermDays: {
      default: false,
      type: Boolean
    },
    paymentsNetTermDays: {
      default: null,
      type: Number
    }
  },

  data: function () {
    const i18nScope = "components.customers-and-subscriptions.subscriptions.badges.expiration-day.labels";
    let label = "";

    if (this.usePaymentsNetTermDays) {
      let paymentsNetTermDays = this.paymentsNetTermDays;
      label = I18n.t(`${i18nScope}.payments-net-term-days`, { paymentsNetTermDays });
    } else {
      let expirationDay = this.expirationDay;
      label = I18n.t(`${i18nScope}.expiration-day`, { expirationDay });
    }

    return {
      popoverContent: label
    };
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".subscriptions-badges-expiration-day[data-toggle=\"popover\"]").popover();
    } catch (error) {
       console.log(error);
    }
  }
};
</script>
