const Routes = {
  paths: {
    customers: {
      show (customerId, customerEntityId) {
        return `/accounts/${customerId}/entity`;
      },
      subscriptions: {
        index (customerId) {
          return `/accounts/${customerId}/subscription_items`;
        }
      },
      invoices: {
        index (customerId) {
          return `/accounts/${customerId}/invoices`;
        }
      },
      logs: {
        index (customerId) {
          return `/accounts/${customerId}/history_items`;
        }
      },
      profile: {
        index (customerId) {
          return `/accounts/${customerId}/perfil`;
        }
      },
      documents: {
        index (customerId) {
          return `/accounts/${customerId}/file_links`;
        }
      }
    }
  }
};

export default Routes;
