<template>
  <the-mask :mask="mask"
            :masked="true"
            :value="value"
            :id="id"
            :name="name"
            type="tel"
            :placeholder="placeholder"
            :class="className"
            @input="inputChanged" />
</template>

<script>

import { TheMask } from "vue-the-mask";

export default {
  props: {
    id: {
      default: null,
      type: String
    },
    name: {
      default: null,
      type: String
    },
    country: {
      default: "BR",
      type: String
    },
    className: {
      default: null,
      type: String
    },
    value: {
      default: null,
      type: String
    },
    placeholder: {
      default: null,
      type: String
    }
  },
  components: { TheMask },
  methods: {
    inputChanged (value) {
      const unformattedValue = value.replace(/\s|\(|\)|-/g, "");

      this.$emit("valueChanged", unformattedValue);
    }
  },
  computed: {
    mask () {
      var generic = "+## ### ### ### ### ###";
      if (this.country === "BR") return ["+## (##) ####-####", "+## (##) #####-####", generic];
      if (this.country === "US") return ["+# (###) ###-#####", generic];
      if (this.country === "CO") return ["+## (###) ###-####", generic];
      if (this.country === "MX") return ["+## (###) ###-####", generic];
      if (this.country === "AR") return ["+## (###) ##-####-####", generic];
      if (this.country === "ES") return ["+## (###) ##-##-##", generic];
      if (this.country === "PT") return ["+### (###) ###-###", generic];

      return generic;
    }
  }
};

</script>
