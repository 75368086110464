export default {
  namespaced: true,
  state: {
    isOpen: false,
    customer: null,
    parentSubscription: null
  },
  mutations: {
    open (state, values) {
      const element = document.createElement("div");
      element.addEventListener("click", () => this.commit("customersAndSubscriptions/subscriptionsDetailsSidebar/close"));
      element.classList.add("background-opacity");
      document.body.appendChild(element);

      state.isOpen = true;
      state.customer = values.customer;
      state.parentSubscription = values.parentSubscription;
    },
    close (state) {
      state.isOpen = false;
      state.parentSubscription = null;
      state.customer = null;

      const element = document.getElementsByClassName("background-opacity")[0];
      if (element) element.remove();
    }
  }
};
