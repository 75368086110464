<template>
  <span class="subscriptions-item-title">
    <label class="subscription-name" :for="labelFor">{{ productName }}</label>
    <small v-if="name || site" class="d-block name-site">
      <span v-if="name">
        {{ name }}
        <span v-if="site">
          |
        </span>
      </span>
      <a :href="site"
         class="subscription-item-site"
         target="_blank"
         v-if="site">
        {{ site }}
      </a>
    </small>
  </span>
</template>

<script>
export default {
  props: {
    id: {
      default: "",
      type: String
    },
    productName: {
      default: "",
      type: String
    },
    name: {
      default: "",
      type: String
    },
    site: {
      default: "",
      type: String
    }
  },
  data () {
    return {
      labelFor: `subscription-checkbox-${this.id}`
    };
  }
};
</script>

<style scoped lang="scss">
  @import "~styles/framework/variables";

  .subscription-name {
    font-weight: bold;
    color: $color-gray-100;
  }
</style>
