const APIRoutes = {
  paths: {
    v1: {
      customers: {
        create () {
          return "/api/v1/customers/";
        }
      },
      customersContact: {
        create (customerId) {
          return `/api/customer_relationship/v1/customers/${customerId}/contacts`;
        }
      },
      subscriptions: {
        addons: {
          index (customerId, subscriptionId) {
            return `/api/v1/customers/${customerId}/subscriptions/${subscriptionId}/addons`;
          }
        }
      },
      reportRequests: {
        create () {
          return "/api/v1/report_requests";
        }
      }
    }
  }
};

export default APIRoutes;
