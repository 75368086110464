<template>
  <span
    class="subscriptions-badges-upgrade-invoice ui-tag ui-tag-primary"
    data-toggle="popover"
    data-trigger="hover"
    data-placement="top"
    data-container="body"
    :data-content="popoverContent">
    <i class="fas fa-arrow-circle-up fa-fw" />
  </span>
</template>

<script>
import I18n from "core/i18n";

export default {
  data: function () {
    const label = I18n.t("components.customers-and-subscriptions.subscriptions.badges.upgrade-invoice.label");

    return {
      popoverContent: label
    };
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".subscriptions-badges-upgrade-invoice[data-toggle=\"popover\"]").popover();
    } catch (error) {
      // console.log(error);
    }
  }
};
</script>
