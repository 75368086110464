export default {
  en: {
    default: {
      year: "numeric", month: "numeric", day: "numeric"
    },
    datepicker: {
      parseStringToDatePattern: "$3-$1-$2"
    }
  },
  es: {
    default: {
      year: "numeric", month: "numeric", day: "numeric"
    },
    datepicker: {
      parseStringToDatePattern: "$3-$2-$1"
    }
  },
  "pt-BR": {
    default: {
      year: "numeric", month: "numeric", day: "numeric"
    },
    datepicker: {
      parseStringToDatePattern: "$3-$2-$1"
    }
  }
};
