<template>
  <div class="history-snapshot"
       :class="'tree-level-' + (treeIndex + 1)"
  >
    <span class="field">{{ field }}: </span>

    <span v-if="value && typeof value === 'object'">
      <HistorySnapshot v-for="(item, key) in value"
                       :key="key"
                       :field="key"
                       :value="item"
                       :tree-index="treeIndex + 1"
      />
    </span>

    <span v-else>
      <span class="d-inline-block">{{ value }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "HistorySnapshot",
  props: {
    field: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: ""
    },
    treeIndex: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/framework/variables";

.history-snapshot {
  font-size: $font-size-sm;
  position: relative;

  .field {
    font-weight: bold;
    color: $color-gray-100;
  }

  .tree-level-1 {
    padding-left: $spacing-sm;
  }

  .tree-level-2 {
    padding-left: $spacing-md;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 1px;
      width: 6px;
      height: 6px;
      border-left: 2px solid $color-gray-40;
      border-bottom: 2px solid $color-gray-40;
    }
  }

  .tree-level-3 {
    padding-left: $spacing-lg;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-left: 2px solid $color-gray-40;
      border-bottom: 2px solid $color-gray-40;
    }
  }

  .tree-level-4 {
    padding-left: $spacing-xl;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 9px;
      width: 6px;
      height: 6px;
      border-left: 2px solid $color-gray-40;
      border-bottom: 2px solid $color-gray-40;
    }
  }

  .tree-level-5 {
    padding-left: $spacing-xxl;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 13px;
      width: 6px;
      height: 6px;
      border-left: 2px solid $color-gray-40;
      border-bottom: 2px solid $color-gray-40;
    }
  }
}
</style>
