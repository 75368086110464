export default {
  core: {
    "communication-in-app": {
      hello: "Olá <strong>{userName}.</strong>",
      "click-text": "Clique aqui para visualizar.",
      "last-step-first-text": "Qualquer dúvida ou problema estamos a disposição no canal <strong>#duvidas-billing</strong> no Slack!",
      "last-step-last-text": "Um abraço, <strong>Commandos Team</strong>",
      "title-rated": "Essa comunicação foi útil para você?"
    },
    "history-comment": {
      "from": "De",
      "to": "Para",
      "text": "Texto simples",
      "reason": "<strong>Motivo:</strong> {reason}"
    }
  },

  "event-history": {
    "created_at": "Data de criação",
    author: "Autor",
    action: "Ação",
    information: "Informações",
    "more_information": "Ver mais informações",
    entities: {
      "customer_relationship": {
        customer: "Cliente",
        "customer_contact": "Contato do cliente"
      },
      billing: {
        subscription: "Configurações de faturamento - Serviço contratado",
        invoice: "Fatura",
        "invoice_item": "Item da fatura"
      },
      "subscription_management": {
        subscription: "Serviço contratado",
        "subscription_attendant": "Atendente vinculado ao serviço contratado"
      },
      taxes: {
        customer: "Configurações de impostos e notas fiscais - Cliente",
        subscription: "Configurações de impostos e notas fiscais - Serviço contratado"
      },
      payments: {
        subscription: "Configurações de pagamento - Serviço contratado",
        charge: "Cobrança",
        "charge_group": "Cobranças",
        "payment_intent": "Inteção de pagamento"
      },
      legacy: {
        "finance_entity": "Cliente",
        account: "Cliente",
        "billing_subscription_item": "Serviço contratado",
        "billing_invoice": "Fatura/Cobrança",
        "billing_invoice_item": "Item da Fatura/Cobrança"
      }
    },
    "event-type": {
      activation: "Ativação",
      create: "Criação",
      update: "Alteração",
      activate: "Ativação",
      "undo_activation": "Desfazer ativação",
      cancel: "Cancelamento",
      comment: "Comentário",
      event: "Evento"
    },
    "from": "De",
    "to": "Para",
    "text": "Texto simples"
  },

  "customers-and-subscriptions": {
    customers: {
      actions: {
        "integrations-warning": "Atenção: adicionar um cliente manualmente não garante a integração com outros sistemas.",
        "box-error": {
          label: "Não foi possível adicionar o cliente <strong class='customer-name'>{CustomerName}</strong>",
          error: "O campo <span class='error-field-name'><strong>{field}</strong></span> é <span class='error-field-type'><strong>{type}</strong></span>"
        },
        "box-success": {
          label: "O cliente <strong>{CustomerName}</strong> foi adicionado com sucesso",
          "create-another-customer": "Clique aqui para adicionar outro Cliente",
          "back-to-home": "Voltar para tela principal"
        },
        new: {
          "main-title": "Adicionar novo cliente",
          "fill-fields": "O preenchimento de todos os campos é obrigatório.",
          "individual-person": "Pessoa Física",
          "legal-entity": "Pessoa Jurídica"
        },
        "form-new-customer": {
          "indentify-str": "Documento (CPF)",
          "indentify-str-entity": "Documento (CNPJ)",
          "full-name": "Nome completo",
          "entity-name": "Nome fantasia",
          "legal-name": "Razão social",
          "phone": "Telefone",
          "zipcode": "CEP",
          "address": "Endereço",
          "district": "Bairro",
          "address-number": "Número",
          "address-plus": "Complemento",
          "country": "País",
          "state": "Estado",
          "city": "Cidade"
        },
        contact: {
          title: "Adicionar Contato",
          "first_name": "Nome",
          "last_name": "Sobrenome",
          email: "E-mail",
          phone: "Telefone",
          kind: {
            title: "Tipo",
            financial: "Financeiro",
            marketing: "Marketing",
            president: "CEO / Dono",
            director: "Diretor",
            admin: "Administrador",
            sales: "Vendedor"
          }
        }
      },
      badges: {
        id: {
          "customer-id": "Customer ID"
        }
      },
      item: {
        menu: {
          "subscriptions": "Serviços Contratados",
          "invoices": "Faturas e Cobranças",
          "logs": "Histórico",
          "customer": "Dados do Cliente",
          "profile": "Painel Financeiro",
          "documents": "Documentos"
        }
      }
    },
    subscriptions: {
      badges: {
        amount: {
          "product-type": {
            recurrent: "Valor recorrente",
            "non-recurrent": "Valor não recorrente"
          },
          labels: {
            "sale-item-price": "Valor do plano",
            "price-readjusted": "Valor reajustado",
            "amount-considering-coupon": "Valor com desconto"
          }
        },
        "blocked-pastdue": {
          blocked: "Bloqueado",
          "past-due": "Em atraso",
          "blocked-and-past-due": "Em atraso e bloqueado"
        },
        discount: {
          "billing-quantity-type": {
            "unlimited": "Ilimitado",
            "limited": "Limitado"
          },
          labels: {
            "billing-quantity-type": "Tempo de utilização",
            discount: "Desconto de {amountWithSymbol}"
          },
          "uses-left": "Tempo de uso restante: {couponUsesLeft} meses"
        },
        "expiration-day": {
          labels: {
            "expiration-day": "Vencimento fixo sempre no dia {expirationDay} de cada mês",
            "payments-net-term-days": "Vencimento {paymentsNetTermDays} dias após a data da emissão da fatura",
            "dont-use-payments-net-term-days": "Dia",
            "use-payments-net-term-days": "Emissão +",
            day: "dia"
          }
        },
        "payment-method": {
          labels: {
            "paid-with": "Pago via {paymentMethod}"
          }
        },
        status: {
          title: {
            trial: "Trial desde:",
            active: "Ativo desde:",
            canceled: "Cancelado desde:",
            ended: "Encerrado desde:"
          }
        },
        "upgrade-invoice": {
          label: "Gerar cobrança de upgrade/upsell"
        },
        id: {
          "subscription-id": "Subscription ID"
        }
      },
      "btn-open-details-sidebar": {
        text: "<strong>Clique aqui</strong> para visualizar os serviços adicionais desse item."
      },
      "details-sidebar": {
        "view-all-subscriptions": "Ver todos os serviços deste cliente",
        addons: {
          title: "Serviços adicionais"
        }
      }
    }
  }
};
