<template>
  <span
    class="subscriptions-badges-discount ui-tag ui-tag-primary"
    data-toggle="popover"
    data-html="true"
    data-trigger="hover"
    data-placement="top"
    data-container="body"
    :data-title=popoverTitle
    :data-content=popoverContent>
    <span>
      -{{ amountWithSymbol }} OFF
    </span>
  </span>
</template>

<script>
import MoneyFormat from "vue-money-format";
import I18n from "core/i18n";

export default {
  props: {
    kind: {
      default: null,
      type: String
    },
    amount: {
      default: null,
      type: Number
    },
    currency: {
      default: null,
      type: String
    },
    billingUnit: {
      default: null,
      type: String
    },
    billingQuantityType: {
      default: "",
      type: String
    },
    billingQuantityTotal: {
      default: null,
      type: Number
    },
    billingQuantityUsed: {
      default: null,
      type: Number
    }
  },

  data: function () {
    const i18nScope = "components.customers-and-subscriptions.subscriptions.badges.discount";
    const billingQuantityTypeLabel = I18n.t(`${i18nScope}.labels.billing-quantity-type`);
    const billingQuantityTypeValue = I18n.t(`${i18nScope}.billing-quantity-type.${this.billingQuantityType}`);

    let amountWithSymbol;
    if (this.kind === "percentual") {
      amountWithSymbol = `${this.amount}%`;
    } else {
      const supplementalPrecision = (["MXN", "BRL", "USD"].indexOf(this.currency) > -1) ? 0 : 2;
      amountWithSymbol = MoneyFormat.methods.formatMoney(this.amount, I18n.locale, this.currency, false, 1, false, supplementalPrecision);
    }

    let billingQuantityUsesLeft;
    if (this.billingQuantityType === "limited") {
      const couponUsesLeft = this.billingQuantityTotal - this.billingQuantityUsed;
      billingQuantityUsesLeft = `<br/> ` + I18n.t(`${i18nScope}.uses-left`, { couponUsesLeft });
    } else {
      billingQuantityUsesLeft = "";
    }

    return {
      amountWithSymbol: amountWithSymbol,
      popoverContent: `${billingQuantityTypeLabel}: ${billingQuantityTypeValue} ${billingQuantityUsesLeft}`,
      popoverTitle: I18n.t(`${i18nScope}.labels.discount`, { amountWithSymbol })
    };
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".subscriptions-badges-discount[data-toggle=\"popover\"]").popover();
    } catch (error) {
      // console.log(error);
    }
  }
};

</script>
