import { render, staticRenderFns } from "./communication-in-app.vue?vue&type=template&id=59b93552&scoped=true&"
import script from "./communication-in-app.vue?vue&type=script&lang=js&"
export * from "./communication-in-app.vue?vue&type=script&lang=js&"
import style0 from "./communication-in-app.vue?vue&type=style&index=0&id=59b93552&scoped=true&lang=scss&"
import style1 from "./communication-in-app.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b93552",
  null
  
)

export default component.exports