<template>
  <div class="action-loading"
       :class="size"
       :title="title">
    <div class="ellipsis" />
    <div class="ellipsis" />
    <div class="ellipsis" />
    <div class="ellipsis" />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      default: "default",
      type: String
    }
  },

  data () {
    return {
      title: this.$t("text.loading")
    };
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";

.action-loading {
  position: relative;
  margin: 0 auto;

  .ellipsis {
    position: absolute;
    border-radius: 50%;
    background: $color-gray-20;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: LoadingAnimationElement1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: LoadingAnimationElement2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: LoadingAnimationElement2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: LoadingAnimationElement3 0.6s infinite;
    }
  }

  &.default {
    width: 64px;
    height: 64px;

    .ellipsis {
      top: 27px;
      width: 11px;
      height: 11px;
    }
  }

  &.small {
    width: 60px;
    height: 20px;

    .ellipsis {
      top: 5px;
      width: 8px;
      height: 8px;
    }
  }
}

@keyframes LoadingAnimationElement1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes LoadingAnimationElement2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

@keyframes LoadingAnimationElement3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

</style>
