<template>
  <div class="new-customer-contact">
    <div v-if="attemptSubmitStatus === 'error'">
      <box-error :errors="errors"/>
    </div>

    <div class="row text-center">
      <h2>{{ $t("components.customers-and-subscriptions.customers.actions.contact.title") }}</h2>
    </div>
    <hr>

    <form
      id="form-new-customer-contact"
      action="#"
      @submit.prevent="">

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group"
               :class="{'field-error': $v.CustomerContactKind.$error}">
            <label for="customer-contact-kind"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.contact.kind.title") }}</label>
            <select id="customer-contact-kind"
                    class="form-control"
                    required="required"
                    v-model="CustomerContactKind">
              <option value="financial">{{ $t(ContactKindTranslation + ".financial") }}</option>
              <option value="marketing">{{ $t(ContactKindTranslation + ".marketing") }}</option>
              <option value="president">{{ $t(ContactKindTranslation + ".president") }}</option>
              <option value="director">{{ $t(ContactKindTranslation + ".director") }}</option>
              <option value="admin">{{ $t(ContactKindTranslation + ".admin") }}</option>
              <option value="sales">{{ $t(ContactKindTranslation + ".sales") }}</option>
            </select>
            <form-validation-errors
              :is-dirty="$v.CustomerContactKind.$dirty"
              :has-error="$v.CustomerContactKind.$error"
              :is-required="$v.CustomerContactKind.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group"
               :class="{'field-error': $v.CustomerContactFirstName.$error}">
            <label for="customer-contact-first-name"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.contact.first_name") }}</label>
            <input id="customer-contact-first-name"
                   type="text"
                   v-model.trim="CustomerContactFirstName"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerContactFirstName.$dirty"
              :has-error="$v.CustomerContactFirstName.$error"
              :is-required="$v.CustomerContactFirstName.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group">
            <label for="customer-contact-last-name"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.contact.last_name") }}</label>
            <input id="customer-contact-last-name"
                   type="text"
                   v-model.trim="CustomerContactLastName"
                   class="form-control">
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group">
            <label for="customer-contact-phone"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.contact.phone") }}</label>
            <core-masked-phone-field id="customer-contact-phone"
                                     class-name="form-control"
                                     :value="CustomerContactPhone"
                                     :country="CustomerCountry"
                                     @valueChanged="changeContactPhone" />
          </div>
        </div>

        <div class="col-xs-12 col-sm-8 col-md-6">
          <div class="form-group"
               :class="{'field-error': $v.CustomerContactEmail.$error}">
            <label for="customer-contact-email"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.contact.email") }}</label>
            <input id="customer-contact-email"
                   type="email"
                   v-model.trim="CustomerContactEmail"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerContactEmail.$dirty"
              :has-error="$v.CustomerContactEmail.$error"
              :is-invalid="$v.CustomerContactEmail.$invalid"
              :is-required="$v.CustomerContactEmail.requied"/>
          </div>
        </div>

        <div class="col-xs-12 text-right">
          <hr class="strong">
          <button type="button"
                  @click="skipStep"
                  class="ui-btn ui-btn-secondary margin-left-small">{{ $t("text.skip") }}</button>
          <button type="button"
                  @click="submitForm"
                  name="button"
                  class="ui-btn ui-btn-primary margin-left-small">{{ $t("text.add") }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BoxError from "./box-error";
import Routes from "core/api-routes.js";
import MaskedPhoneField from "core/components/masked-phone-field";
import FormValidationErrors from "./form-validation-errors";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    BoxError,
    FormValidationErrors,
    CoreMaskedPhoneField: MaskedPhoneField
  },

  props: {
    CustomerId: {
      type: Number,
      required: true
    },
    CustomerCountry: {
      type: String,
      default: "BR"
    }
  },

  data: function () {
    return {
      attemptSubmitStatus: null,
      CustomerContactFirstName: "",
      CustomerContactLastName: "",
      CustomerContactEmail: "",
      CustomerContactPhone: "",
      CustomerContactKind: ""
    };
  },

  validations: {
    CustomerContactFirstName: {
      required
    },

    CustomerContactEmail: {
      required,
      email
    },

    CustomerContactKind: {
      required
    }
  },

  computed: {
    ContactKindTranslation() {
      return "components.customers-and-subscriptions.customers.actions.contact.kind";
    }
  },

  methods: {
    backToTopScreen () {
      window.scrollTo({
        "behavior": "smooth",
        "left": 0,
        "top": 0
      });
    },

    changeContactPhone (phone) {
      this.CustomerContactPhone = phone;
    },

    skipStep: function () {
      this.$parent.stepFlow = "success";
      this.backToTopScreen();
    },

    submitForm () {
      this.$v.$touch();
      const url = Routes.paths.v1.customersContact.create(this.CustomerId);
      const paramsCustomerContact = {
        "customer_relationship/customer_contact": {
          first_name: this.CustomerContactFirstName,
          last_name: this.CustomerContactLastName,
          email: this.CustomerContactEmail,
          phone: this.CustomerContactPhone,
          type: this.CustomerContactKind
        }
      };

      if (!this.$v.$invalid) {
        return this.$http.post(url, paramsCustomerContact).then(function (e) {
          this.$parent.stepFlow = "success";
          this.backToTopScreen();
        }, function (response) {
          const error = response.body.errors;
          this.errors = [{
            field: Object.keys(error)[0],
            type: error[Object.keys(error)[0]][0]["error_type"]
          }];
          this.attemptSubmitStatus = "error";
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.form-group {
  margin-bottom: $spacing-lg;

  .form-control-feedback {
    display: none !important;
  }

  .control-label {
    padding: 0;
    font-weight: bold;
    color: $color-gray-50;
  }

  &.field-error {
    .control-label,
    .help-block {
      color: $color-red-60;
    }

    .form-control {
      border-color: $color-red-60;
      color: $color-red-60;
    }
  }
}
</style>
