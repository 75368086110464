<template>
  <div class="customer-type-individual-person-row">
    <div v-if="attemptSubmitStatus === 'error'">
      <box-error :customer-name="CustomerPersonalName" :errors="errors"/>
    </div>

    <form
      id="form-customer-type-individual-person"
      action="#"
      @submit.prevent="">

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group"
               :class="{'field-error': $v.CustomerPersonalDocument.$error}">
            <label for="customer-individual-person-document"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.indentify-str") }}</label>
            <input id="customer-individual-person-document"
                   type="text"
                   @keypress="charactersFormatDocument()"
                   v-model.trim="CustomerPersonalDocument"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerPersonalDocument.$dirty"
              :has-error="$v.CustomerPersonalDocument.$error"
              :is-required="$v.CustomerPersonalDocument.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-6">
          <div class="form-group"
               :class="{'field-error': $v.CustomerPersonalName.$error}">
            <label for="customer-individual-person-full-name"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.full-name") }}</label>
            <input id="customer-individual-person-full-name"
                   type="text"
                   v-model.trim="CustomerPersonalName"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerPersonalName.$dirty"
              :has-error="$v.CustomerPersonalName.$error"
              :is-required="$v.CustomerPersonalName.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group">
            <label for="customer-individual-person-phone"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.phone") }}</label>
            <core-masked-phone-field id="customer-individual-person-phone"
                                     class-name="form-control"
                                     :value="CustomerPersonalPhone"
                                     :country="CustomerCountry"
                                     @valueChanged="changeCustomerPhone" />
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-2">
          <div class="form-group"
               :class="{'field-error': $v.CustomerCountry.$error}">
            <label for="customer-individual-person-country"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.country") }}</label>
            <select-countries id="customer-individual-person-country"
                              name="customer-individual-person-country"
                              @select-country="getSelectedCountry"/>
            <form-validation-errors
              :is-dirty="$v.CustomerCountry.$dirty"
              :has-error="$v.CustomerCountry.$error"
              :is-required="$v.CustomerCountry.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group"
               :class="{'field-error': $v.CustomerState.$error}">
            <label for="customer-individual-person-state"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.state") }}</label>
            <select v-if="CustomerCountry === 'BR'"
                    id="customer-individual-person-state"
                    class="form-control"
                    @change="getBrasilCitys"
                    v-model.trim="CustomerState"
                    name="customer-individual-person-country">
              <option value="">{{ $t("text.selectOption") }}</option>
              <option v-for="state in states"
                      :key="state.name"
                      :data-state-initials="state.state"
                      :value="state.state">{{ state.name }}</option>
            </select>
            <input v-else
                   id="customer-individual-person-state"
                   type="text"
                   v-model.trim="CustomerState"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerState.$dirty"
              :has-error="$v.CustomerState.$error"
              :is-required="$v.CustomerState.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group"
               :class="{'field-error': $v.CustomerCity.$error}">
            <label for="customer-individual-person-city"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.city") }}</label>
            <select v-if="CustomerCountry === 'BR'"
                    id="customer-individual-person-city"
                    class="form-control"
                    v-model.trim="CustomerCity"
                    name="customer-individual-person-country">
              <option value="">{{ $t("text.selectOption") }}</option>
              <option v-for="city in cities"
                      :key="city.name"
                      :value="city.name">{{ city.name }}</option>
            </select>
            <input v-else
                   id="customer-individual-person-city"
                   type="text"
                   v-model.trim="CustomerCity"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerCity.$dirty"
              :has-error="$v.CustomerCity.$error"
              :is-required="$v.CustomerCity.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4">
          <div class="form-group"
               :class="{'field-error': $v.CustomerDistrict.$error}">
            <label for="customer-individual-person-district"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.district") }}</label>
            <input id="customer-individual-person-district"
                   type="text"
                   v-model.trim="CustomerDistrict"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerDistrict.$dirty"
              :has-error="$v.CustomerDistrict.$error"
              :is-required="$v.CustomerDistrict.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-2">
          <div class="form-group"
               :class="{'field-error': $v.CustomerZipcode.$error}">
            <label for="customer-individual-person-zipcode"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.zipcode") }}</label>
            <input id="customer-individual-person-zipcode"
                   type="text"
                   @keypress="charactersFormatZipcode()"
                   v-model.trim="CustomerZipcode"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerZipcode.$dirty"
              :has-error="$v.CustomerZipcode.$error"
              :is-required="$v.CustomerZipcode.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4">
          <div class="form-group"
               :class="{'field-error': $v.CustomerAddress.$error}">
            <label for="customer-individual-person-address"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.address") }}</label>
            <input id="customer-individual-person-address"
                   type="text"
                   v-model.trim="CustomerAddress"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerAddress.$dirty"
              :has-error="$v.CustomerAddress.$error"
              :is-required="$v.CustomerAddress.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-2">
          <div class="form-group"
               :class="{'field-error': $v.CustomerAddressNumber.$error}">
            <label for="customer-individual-person-address-number"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.address-number") }}</label>
            <input id="customer-individual-person-address-number"
                   type="text"
                   v-model.trim="CustomerAddressNumber"
                   class="form-control">
            <form-validation-errors
              :is-dirty="$v.CustomerAddressNumber.$dirty"
              :has-error="$v.CustomerAddressNumber.$error"
              :is-required="$v.CustomerAddressNumber.requied"/>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4">
          <div class="form-group">
            <label for="customer-individual-person-address-plus"
                   class="control-label">{{ $t("components.customers-and-subscriptions.customers.actions.form-new-customer.address-plus") }}</label>
            <input id="customer-individual-person-address-plus"
                   type="text"
                   v-model.trim="CustomerAddressPlus"
                   class="form-control">
            <form-validation-errors/>
          </div>
        </div>

        <div class="col-xs-12 text-right">
          <hr class="strong">
          <a href="/"
             tabindex="2"
             class="ui-btn ui-btn-secondary">{{ $t("text.cancel") }}</a>
          <button type="button"
                  tabindex="1"
                  @click="submitForm"
                  name="button"
                  class="ui-btn ui-btn-primary">{{ $t("text.next") }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SelectCountries from "core/components/select-countries";
import Routes from "core/api-routes.js";
import MaskedPhoneField from "core/components/masked-phone-field";
import BoxError from "./box-error";
import FormValidationErrors from "./form-validation-errors";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    SelectCountries,
    BoxError,
    FormValidationErrors,
    CoreMaskedPhoneField: MaskedPhoneField
  },

  data: function () {
    return {
      CustomerPersonalDocument: "",
      CustomerPersonalName: "",
      CustomerPersonalPhone: "",
      CustomerZipcode: "",
      CustomerAddress: "",
      CustomerDistrict: "",
      CustomerAddressPlus: "",
      CustomerAddressNumber: "",
      CustomerCountry: "",
      CustomerState: "",
      CustomerCity: "",
      validationFields: null,
      attemptSubmit: false,
      attemptSubmitStatus: null,
      errors: [{
        field: null,
        type: null
      }],
      states: [{
        name: "Acre",
        state: "AC"
      }, {
        name: "Alagoas",
        state: "AL"
      }, {
        name: "Amapá",
        state: "AP"
      }, {
        name: "Amazonas",
        state: "AM"
      }, {
        name: "Bahia",
        state: "BA"
      }, {
        name: "Ceará",
        state: "CE"
      }, {
        name: "Distrito Federal",
        state: "DF"
      }, {
        name: "Espírito Santo",
        state: "ES"
      }, {
        name: "Goiás",
        state: "GO"
      }, {
        name: "Maranhão",
        state: "MA"
      }, {
        name: "Mato Grosso",
        state: "MT"
      }, {
        name: "Mato Grosso do Sul",
        state: "MS"
      }, {
        name: "Minas Gerais",
        state: "MG"
      }, {
        name: "Pará",
        state: "PA"
      }, {
        name: "Paraíba",
        state: "PB"
      }, {
        name: "Paraná",
        state: "PR"
      }, {
        name: "Pernambuco",
        state: "PE"
      }, {
        name: "Piauí",
        state: "PI"
      }, {
        name: "Rio de Janeiro",
        state: "RJ"
      }, {
        name: "Rio Grande do Norte",
        state: "RN"
      }, {
        name: "Rio Grande do Sul",
        state: "RS"
      }, {
        name: "Rondônia",
        state: "RO"
      }, {
        name: "Roraima",
        state: "RR"
      }, {
        name: "Santa Catarina",
        state: "SC"
      }, {
        name: "São Paulo",
        state: "SP"
      }, {
        name: "Sergipe",
        state: "SE"
      }, {
        name: "Tocantins",
        state: "TO"
      }],
      cities: []
    };
  },

  validations: {
    CustomerPersonalDocument: {
      required
    },

    CustomerPersonalName: {
      required
    },

    CustomerZipcode: {
      required
    },

    CustomerAddress: {
      required
    },

    CustomerDistrict: {
      required
    },

    CustomerAddressNumber: {
      required
    },

    CustomerCountry: {
      required
    },

    CustomerState: {
      required
    },

    CustomerCity: {
      required
    }
  },

  methods: {
    getSelectedCountry (e) {
      this.CustomerCountry = e.dataSearch;
    },

    charactersFormatDocument: function (e) {
      var charCode = (window.event.which) ? window.event.which : window.event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) { // space, numbers and dot
        window.event.preventDefault();
      } else {
        return true;
      }
    },

    charactersFormatZipcode: function (e) {
      var charCode = (window.event.which) ? window.event.which : window.event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && // only numbers
          charCode !== 45 // -
      ) {
        window.event.preventDefault();
      } else {
        return true;
      }
    },

    changeCustomerCountry () {
      // this reset state and city customer fields
      this.CustomerCity = "";
      this.CustomerState = "";
    },

    changeCustomerPhone (phone) {
      this.CustomerPersonalPhone = phone;
    },

    getBrasilCitys: function (e) {
      this.CustomerCity = "";
      const stateInitials = (e.target.options[e.target.selectedIndex].attributes["data-state-initials"].value);
      const dataURL = "https://cidades.rdstation.com.br/api/cidades?limited=false&state_acronym=" + stateInitials;
      const self = this;

      const xhr = new XMLHttpRequest();
      xhr.open("GET", dataURL, true);
      xhr.onload = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const res = xhr.responseText;
            self.cities = JSON.parse(res).cities;
          }
        }
      };
      xhr.onerror = function (e) {};
      xhr.send(null);
    },

    backToTopScreen () {
      window.scrollTo({
        "behavior": "smooth",
        "left": 0,
        "top": 0
      });
    },

    submitForm () {
      this.$v.$touch();
      const url = Routes.paths.v1.customers.create();
      const paramsIndividualPerson = {
        customer: {
          legal_name: this.CustomerPersonalName,
          name: this.CustomerPersonalName,
          document: this.CustomerPersonalDocument,
          person_type: "individual",
          address: {
            street: this.CustomerAddress,
            district: this.CustomerDistrict,
            zip_code: this.CustomerZipcode,
            number: this.CustomerAddressNumber,
            complement: this.CustomerAddressPlus,
            city: this.CustomerCity,
            state: this.CustomerState,
            country_code: this.CustomerCountry,
            phone: this.CustomerPersonalPhone
          }
        }
      };

      if (!this.$v.$invalid) {
        return this.$http.post(url, paramsIndividualPerson).then(function (response) {
          const customer = response.body.customer;
          this.$parent.CustomerId = parseInt(customer.id);
          this.$parent.CustomerName = customer.name;
          this.$parent.CustomerCountry = customer.country;

          this.$parent.stepFlow = "customer-contact";
          this.backToTopScreen();
        }, function (response) {
          const error = response.body.errors;
          this.errors = [{
            field: Object.keys(error)[0],
            type: error[Object.keys(error)[0]][0]["error_type"]
          }];
          this.attemptSubmitStatus = "error";
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.form-group {
  margin-bottom: $spacing-lg;

  .form-control-feedback {
    display: none !important;
  }

  .control-label {
    padding: 0;
    font-weight: bold;
    color: $color-gray-50;
  }

  &.field-error {
    .control-label,
    .help-block {
      color: $color-red-60;
    }

    .form-control {
      border-color: $color-red-60;
      color: $color-red-60;
    }
  }
}
</style>
