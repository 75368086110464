<template>
  <div class="select-countries">
    <select :id="id"
            class="form-control"
            :name="name"
            v-model="selectedOption"
            @change="selectedCountry">
      <option value="">{{ $t("text.selectOption") }}</option>
      <option v-for="(option, index) in options"
              :key="index"
              :value="option.value">
        {{ $t(`text.countries.${option.value}`) }}
      </option>
    </select>
  </div>
</template>

<script>
import Countries from "../countries";

export default {
  data: function () {
    return {
      selectedOption: "",
      options: Countries
    };
  },

  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  methods: {
    selectedCountry (e) {
      this.$emit("select-country", { dataSearch: e.target.value });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.form-group {
  &.field-error {
    .form-control {
      border-color: $color-red-60;
      color: $color-red-60;
    }
  }
}
</style>
