<template>
  <table class="history-item">
    <div class="group">
      <div class="row">
        <div class="column col-xs-2 text-center">
          <span class="badge">{{ date }}</span>
          <small>{{ hour }}</small>
        </div>
        <div class="column col-xs-2 text-center" :title="author.email || author.name">
          {{ author.name }}
        </div>
        <div class="column col-xs-2 text-center">
          <span v-if="$te(`components.event-history.event-type.${eventType}`)">
            {{ $t(`components.event-history.event-type.${eventType}`) }}
          </span>
          <span v-else>
            {{ eventType }}
          </span>
        </div>
        <div class="column col-xs-6">
          <span v-if="domain != 'legacy'" class="badge">
            {{ version || 'v1' }}
          </span>

          <span class="badge" :title="`${version || 'v1'}.${domain}.${subject}`">
            <div v-if="eventType === 'comment'">
              {{ $t("components.event-history.text") }}
            </div>

            <div v-if="eventType !== 'comment' && $te(`components.event-history.entities.${domain}.${subject}`)">
              {{ $t(`components.event-history.entities.${domain}.${subject}`) }}

              <span v-if="subject !== 'subscription_attendant'">
                {{ "#" + subjectId }}
              </span>
            </div>

            <div v-if="eventType !== 'comment' && !$te(`components.event-history.entities.${domain}.${subject}`)">
              {{ subject }}
              <span>
                {{ "#" + subjectId }}
              </span>
            </div>
          </span>

          <div class="margin-top-default text-size-sm" v-show="filteredComment">
            <span v-if="isClearText">{{ clearText(filteredComment) }}</span>

            <span v-if="!isClearText && filteredComment">
              <Legacy-Log v-for="(item, index) in formatObject(filteredComment)"
                          :key="index"
                          :comment="item"
              />
              <Reason :text="reason" />
            </span>
          </div>

          <div v-if="!isClearText && hasSnapshot" class="d-block margin-top-default">
            <a class="show-snapshot collapsed"
               data-toggle="collapse"
               :href="'#' + _uid"
               aria-expanded="false"
               :aria-controls="_uid"
            >
              {{ $t("components.event-history.more_information") }}
            </a>

            <div class="collapse" :id="_uid">
              <div class="snapshot">
                <Snapshot v-for="(item, key) in snapshot"
                          :key="key"
                          :field="key"
                          :value="item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </table>
</template>

<script>
import Log, { hasFromTo, isObjectComment } from "../../core/components/history/log";
import Reason from "../../core/components/reason";
import Snapshot from "./log-snapshot";

export default {
  components: {
    "Legacy-Log": Log,
    "Snapshot": Snapshot,
    "Reason": Reason
  },

  props: {
    date: {
      type: String,
      required: true
    },
    hour: {
      type: String,
      required: true
    },
    author: {
      type: Object,
      required: true
    },
    subject: {
      type: String,
      required: true
    },
    subjectId: {
      type: String,
      required: true
    },
    snapshot: {
      type: Object,
      default: function () { return {}; }
    },
    modifications: {
      type: Object,
      default: function () { return {}; }
    },
    domain: {
      type: String,
      required: true
    },
    version: {
      type: String,
      required: true
    },
    eventType: {
      type: String,
      default: ""
    },
    eventTransaction: {
      type: Object,
      default: function () { return {}; }
    }
  },

  computed: {
    filteredComment () {
      if (this.isClearText) return this.snapshot.comment;
      return this.modifications;
    },

    isClearText () {
      return this.snapshot.comment;
    },

    hasSnapshot () {
      return Object.keys(this.snapshot).length > 0;
    },

    reason () {
      return this.eventTransaction && this.eventTransaction.reason;
    }
  },

  methods: {
    clearText: function (value) {
      const colonFixed = value.replace(/:/g, ": ");
      const withoutArrayFunction = colonFixed.replace(/(=>)/g, ": ");
      const arrayFixed = withoutArrayFunction.replace(/\[([^[]+)\]/g, (match, value) => value.replace(/,/g, " -"));
      const withoutBraces = arrayFixed.replace(/(\{|\})/g, "");
      const commaFixed = withoutBraces.replace(/(,)/g, "<br />");
      const removeTag = commaFixed.replace(/<[^>]*>/g, "");
      const withoutQuotation = removeTag.replace(/("|')/g, "");
      const fixHttpLink = withoutQuotation.replace(/http:\ \//gi, "http:/");
      const fixHttpsLink = fixHttpLink.replace(/https:\ \//gi, "https:/");
      return fixHttpsLink;
    },

    formatObject: function (comment) {
      if (!comment) return [];

      return Object.keys(comment).map((key) => {
        const value = comment[key];

        if (hasFromTo(value)) {
          return {
            name: key,
            value: {
              from: value[0] || "null",
              to: value[1] || "' '"
            }
          };
        }

        if (isObjectComment(value)) {
          return {
            name: key,
            value: this.formatObject(value)
          };
        }

        return {
          name: key,
          value: value || "' '"
        };
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";

.history-item {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  border-collapse: separate;
  border-width: 1px;
  border-style: solid;
  border-top: 0;
  border-color: $color-gray-20;
  border-spacing: 0;
  background-color: $color-white;
  display: table;

  &:last-child {
    border-radius: 0 0 $border-radius-base $border-radius-base;
  }

  .badge {
    padding: $spacing-xs $spacing-sm;
  }

  .group {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;

    .row {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;

      .column {
        padding: $spacing-lg;
        vertical-align: middle;
        border-right: 1px solid $color-gray-20;
        color: $color-gray-60;
        font-size: $font-size-md;
        word-break: break-word;
        position: static;
        float: none;
        display: table-cell;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .show-snapshot {
    margin-bottom: -1px;
    display: inline-block;
    text-align: center;
    border-radius: $border-radius-base;
    padding: $spacing-xs $spacing-sm;
    color: $color-blue-50;
    font-size: $font-size-sm;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &:hover,
    &:active {
      background-color: $color-gray-5;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
      color: $color-blue-50;
    }

    &:not(.collapsed) {
      border-radius: $border-radius-base $border-radius-base 0 0;
      border-color: $color-gray-10;
      border-bottom: 0;
      background-color: $color-gray-5;
      text-decoration: none;
    }
  }

  .snapshot {
    padding: $spacing-lg;
    background-color: $color-gray-5;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    border: 1px solid $color-gray-10;
  }
}
</style>
