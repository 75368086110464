<template>
  <div class="reason-history" v-html="$t('components.core.history-comment.reason', { reason: text })" v-if="text" />
</template>
<script>
export default {
  name: "Reason",
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>
<style scoped lang="scss">
@import "~styles/framework/variables";

.reason-history {
  margin-left: $spacing-lg;
  margin-top: $spacing-lg;
  padding: $spacing-lg;
  border-radius: $border-radius-base;
  border: 1px solid $color-gray-20;
}
</style>
