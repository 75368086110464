<template>
  <div class="subscription-divider half-gutters cursor-pointer"
       @click="openSideBar">
    <p v-html="$t('components.customers-and-subscriptions.subscriptions.btn-open-details-sidebar.text')" />
  </div>
</template>

<script>
export default {
  props: {
    parentSubscription: {
      default: null,
      type: Object
    },
    customer: {
      default: null,
      type: Object
    }
  },
  methods: {
    openSideBar () {
      return this.$store.commit("customersAndSubscriptions/subscriptionsDetailsSidebar/open", {
        customer: this.customer,
        parentSubscription: this.parentSubscription
      });
    }
  }
};
</script>
