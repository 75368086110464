<template lang="html">
  <span class="help-block">
    <div v-if="isDirty" class="form-validation-errors">
      <span class="error-invalid" v-if="hasError && isInvalid">{{ $t('text.errors.invalid') }}</span>
      <span class="error-required" v-else-if="hasError">{{ $t('text.errors.required') }}</span>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    hasError: {
      default: false,
      type: Boolean
    },

    isRequired: {
      default: false,
      type: Boolean
    },

    isInvalid: {
      default: false,
      type: Boolean
    },

    isDirty: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.help-block {
  height: 20px;
  margin-top: 0;

  .form-validation-errors {
    span {
      font-size: 12px;
    }
  }
}
</style>
