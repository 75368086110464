import dateTimeFormats from "../translations/date-time-formats";

const CoreDate = {
  parseStringToDate (locale, stringDate) {
    if (!locale) return null;
    if (!stringDate) return null;

    const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    const patternOrder = dateTimeFormats[locale].datepicker.parseStringToDatePattern;

    return new Date(`${stringDate.replace(pattern, patternOrder)} 00:00:00`);
  }
};

export default CoreDate;
