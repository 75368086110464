<template>
  <div class="history-comment">
    <div class="history-comment-log" v-if="isLog">
      <span class="ui-tag ui-tag-id">{{ logType }}</span>
      <Log v-for="(item, index) in contentFormatted"
           :key="index"
           :comment="item" />
      <Reason :text="reason" />
    </div>
    <div class="history-comment-text" v-else >
      <span class="ui-tag ui-tag-id">{{ logType }}</span>
      <span class="ui-tag ui-tag-primary">{{ $t("components.core.history-comment.text") }}</span>
      <div class="log" v-html="contentFormatted" />
    </div>
  </div>
</template>

<script>
import Log, { hasFromTo, isObjectComment } from "./log";
import Reason from "../reason";

export default {
  components: {
    "Log": Log,
    "Reason": Reason
  },

  props: {
    content: {
      type: [Object, String],
      default: ""
    },
    logType: {
      type: String,
      default: ""
    },
    reason: {
      type: String,
      default: ""
    }
  },

  data () {
    return {
      isLog: isObjectComment(this.content),
      contentFormatted: this.formatComment(this.content)
    };
  },

  methods: {
    clearText: function (value) {
      const colonFixed = value.replace(/:/g, ": ");
      const withoutArrayFunction = colonFixed.replace(/(=>)/g, ": ");
      const arrayFixed = withoutArrayFunction.replace(/\[([^[]+)\]/g, (match, value) => value.replace(/,/g, " -"));
      const withoutBraces = arrayFixed.replace(/(\{|\})/g, "");
      const removeTag = withoutBraces.replace(/<[^>]*>/g, "");
      const withoutQuotation = removeTag.replace(/("|')/g, "");
      const fixHttpLink = withoutQuotation.replace(/http:\ \//gi, "http:/");
      const fixHttpsLink = fixHttpLink.replace(/https:\ \//gi, "https:/");
      return fixHttpsLink;
    },

    formatObject: function (comment) {
      return Object.keys(comment).map((key) => {
        const value = comment[key];

        if (hasFromTo(value)) {
          return {
            name: key,
            value: {
              from: value[0] || "null",
              to: value[1] || "' '"
            }
          };
        }

        if (isObjectComment(value)) {
          return {
            name: key,
            value: this.formatObject(value)
          };
        }

        return {
          name: key,
          value: value || "' '"
        };
      });
    },

    formatComment: function (comments) {
      if (!isObjectComment(comments)) {
        return this.clearText(comments);
      }

      return this.formatObject(comments);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";


.log {
  padding: $spacing-xs $spacing-lg 0 $spacing-lg;
  line-height: $line-height-sm;
}

.history-comment {
  font-size: $font-size-sm;

  &-log {
    padding: 5px 0;
  }

  &-text {
    margin-top: $spacing-md;
  }
}
</style>
