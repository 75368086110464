export default {
  core: {
    "communication-in-app": {
      hello: "¡Hola <strong>{userName}.</strong>",
      "click-text": "Haga clic aquí para ver.",
      "last-step-first-text": "Cualquier duda o problema estamos a disposición en el canal <strong>#duvidas-billing</strong> en el Slack",
      "last-step-last-text": "Un abrazo, <strong>Commandos Team</strong>",
      "title-rated": "¿Esta comunicación fue útil para usted?"
    },
    "history-comment": {
      "from": "De",
      "to": "a",
      "text": "Texto simple",
      "reason": "<strong>Razón:</strong> {reason}"
    }
  },

  "event-history": {
    "created_at": "Fecha de creación",
    author: "Autor",
    action: "Acción",
    information: "Información",
    "more_information": "Ver más información",
    entities: {
      "customer_relationship": {
        customer: "Cliente",
        "customer_contact": "Contacto del cliente"
      },
      billing: {
        subscription: "Configuraciones de facturación - Servicio contratado",
        invoice: "Factura",
        "invoice_item": "Artículo de Factura"
      },
      "subscription_management": {
        subscription: "Servicio contratado",
        "subscription_attendant": "Operador vinculado al servicio contratado"
      },
      taxes: {
        customer: "Configuración de impuestos - Cliente",
        subscription: "Configuración de impuestos - Servicio contratado"
      },
      payments: {
        subscription: "Configuración de pago - Servicio contratado",
        charge: "Cargo",
        "charge_group": "Cargos",
        "payment_intent": "Intención de pago"
      },
      legacy: {
        "finance_entity": "Cliente",
        account: "Cliente",
        "billing_subscription_item": "Servicio contratado",
        "billing_invoice": "Factura/Cargo",
        "billing_invoice_item": "Artículo de Factura/Cargo"
      }
    },
    "event-type": {
      activation: "Activación",
      create: "Creación",
      update: "Cambiar",
      comment: "Comentario",
      event: "Evento"
    },
    "from": "De",
    "to": "a",
    "text": "Texto sin formato"
  },

  "customers-and-subscriptions": {
    customers: {
      actions: {
        "integrations-warning": "Atención: la adición manual de un cliente no garantiza la integración con otros sistemas.",
        "box-error": {
          label: "No se puede agregar el cliente <strong class='customer-name'>{CustomerName}</strong>",
          error: "El campo <span class='error-field-name'><strong>{field}</strong></span> es <span class='error-field-type'><strong>{type}</strong></span>"
        },
        "box-success": {
          label: "El cliente <strong>{CustomerName}</strong> se ha agregado con éxito",
          "create-another-customer": "Haga clic aquí para agregar otro cliente",
          "back-to-home": "Volver a la pantalla principal"
        },
        new: {
          "main-title": "Agregar nuevo cliente",
          "fill-fields": "El relleno de todos los campos es obligatorio.",
          "individual-person": "Persona particular",
          "legal-entity": "Entidad jurídica"
        },
        "form-new-customer": {
          "indentify-str": "Documento (CPF)",
          "indentify-str-entity": "Documento (CNPJ)",
          "full-name": "Nombre completo",
          "entity-name": "Nombre fantasía",
          "legal-name": "Razão social",
          "phone": "Teléfono",
          "zipcode": "Código postal",
          "address": "Dirección",
          "district": "Barrio",
          "address-number": "Número",
          "address-plus": "Complemento",
          "country": "País",
          "state": "Estado",
          "city": "Ciudad"
        },
        contact: {
          title: "Agregar contacto",
          "first_name": "Nombre",
          "last_name": "Apellido",
          email: "Correo electrónico",
          phone: "Teléfono",
          kind: {
            title: "Tipo",
            financial: "Financiero",
            marketing: "Marketing",
            president: "CEO / Propietario",
            director: "Director",
            admin: "Administrador",
            sales: "Vendedor"
          }
        }
      },
      badges: {
        id: {
          "customer-id": "Customer ID"
        }
      },
      item: {
        menu: {
          "subscriptions": "Servicios Contratados",
          "invoices": "Facturas e Cobranzas",
          "logs": "Historia",
          "customer": "Datos del Cliente",
          "profile": "Panel Financiero",
          "documents": "Documento"
        }
      }
    },
    subscriptions: {
      badges: {
        amount: {
          "product-type": {
            recurrent: "Valor recurrente",
            "non-recurrent": "Valor no recurrente"
          },
          labels: {
            "sale-item-price": "Precio del plan",
            "price-readjusted": "Valor reajustado",
            "amount-considering-coupon": "Valor con descuento"
          }
        },
        "blocked-pastdue": {
          blocked: "Bloqueado",
          "past-due": "Retrasado",
          "blocked-and-past-due": "Retrasado y bloqueado"
        },
        discount: {
          "billing-quantity-type": {
            "unlimited": "Ilimitado",
            "limited": "Limitado"
          },
          labels: {
            "billing-quantity-type": "Tiempo de utilización",
            discount: "Discuento de {amountWithSymbol}"
          },
          "uses-left": "Tiempo de uso restante: {couponUsesLeft} meses"
        },
        "expiration-day": {
          labels: {
            "expiration-day": "Vencimiento fija siempre el {expirationDay} de cada mes",
            "payments-net-term-days": "Vencimiento {paymentsNetTermDays} días después de la fecha de generación de la factura",
            "dont-use-payments-net-term-days": "Día",
            "use-payments-net-term-days": "Generación +",
            day: "Día"
          }
        },
        "payment-method": {
          labels: {
            "paid-with": "Pagado con {paymentMethod}"
          }
        },
        status: {
          title: {
            trial: "Trial desde:",
            active: "Activo desde:",
            canceled: "Cancelado desde:",
            ended: "Cerrado desde:"
          }
        },
        "upgrade-invoice": {
          label: "Generar factura de upgrade / upsell"
        },
        id: {
          "subscription-id": "Subscription ID"
        }
      },
      "btn-open-details-sidebar": {
        text: "<strong>Haga clic aquí</strong> para ver los addons de este elemento."
      },
      "details-sidebar": {
        "view-all-subscriptions": "Ver todos los servicios de este cliente",
        addons: {
          title: "Add-ons"
        }
      }
    }
  }
};
