<template>
  <div class="log">
    <span class="log-attribute">{{ comment.name }} - </span>
    <span class="log-value">
      <span v-if="hasFromTo(comment.value)">
        {{ $t("components.core.history-comment.from") }}:
        <span class="log-value-from">{{ comment.value.from }}</span>
        {{ $t("components.core.history-comment.to") }}:
        <span class="log-value-to">{{ comment.value.to }}</span>
      </span>
      <span v-else-if="isText(comment.value)" class="log-value-text">{{ comment.value }}</span>
      <Log v-else-if="isObjectComment(comment)"
           v-for="(item, index) in comment.value"
           :key="index"
           :comment="item" />
    </span>
  </div>
</template>

<script>
export const hasFromTo = function (value) {
  if (!value) {
    return false;
  }

  const isArray = (Array.isArray(value) && value.length === 2);
  const isObject = isObjectComment(value) && typeof value.from !== "undefined";

  return isArray || isObject;
};

export const isObjectComment = function (value) {
  return value && typeof value === "object" && value.constructor === Object;
};

export const isText = function (value) {
  return (typeof value === "string") || (typeof value === "number")
};

export default {
  name: "Log",

  props: {
    comment: {
      type: [Object, String],
      default: ""
    }
  },

  methods: {
    hasFromTo,
    isObjectComment,
    isText,
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";

.log {
  &-attribute {
    font-weight: bold;
    color: $color-gray-100;
  }

  &-value {
    &-from,
    &-to {
      font-weight: bold;
    }

    .log {
      font-size: 13px;
      padding: 0 15px;
    }
  }
}
</style>
