import Vue from "vue";
import VCalendar from "v-calendar";
import I18n from "core/i18n";
import Store from "core/store";
import "v-calendar/lib/v-calendar.min.css";
import VueIntro from "vue-introjs";

Vue.use(VCalendar, {
  firstDayOfWeek: 2, // Monday
  popoverVisibility: "focus",
  locale: I18n.locale
});

Vue.use(VueIntro);

const components = [
  "history/comment",
  "date-picker",
  "communication-in-app",
  "company-identification-logo-or-name",
  "masked-phone-field",
  "select-countries"
];

document.addEventListener("DOMContentLoaded", () => {
  window.reloadCoreVueComponents = function () {
    components.forEach(function (component) {
      const tagName = `core-${component.replace(/\//g, "-")}`;
      const elements = document.getElementsByTagName(tagName);

      // Do not initialize if element was not rendered
      if (elements.length) {
        var elementsList = Array.prototype.slice.call(elements);

        elementsList.forEach(function (element) {
          const data = element.getAttribute("data");
          const importedComponent = require(`./components/${component}`);

          // Get props from server side rendered data attribute
          let props = {};
          if (data) props = JSON.parse(data);

          new Vue({
            i18n: I18n,
            store: Store,
            el: element,
            render: h => h(importedComponent.default, { props })
          });
        });
      }
    });
  };

  window.reloadCoreVueComponents();
});
