import en from "./text/en";
import es from "./text/es";
import ptbr from "./text/pt-BR";

export default {
  en: {
    text: en
  },
  es: {
    text: es
  },
  "pt-BR": {
    text: ptbr
  }
};
