<template>
  <span class="customers-item-title">
    <span class="d-flex">
      <span id="customer-and-subscription-customer-name" class="customer-name-and-identification-document">
        <h2 class="margin-top-empty" v-if="titleHtmlTag == 'h2'">
          <strong>{{ customerName }}</strong>
          <small>{{ customerDocument }}</small>
        </h2>
        <h3 class="margin-top-empty text-size-xl" v-if="titleHtmlTag == 'h3'">
          <strong>{{ customerName }}</strong>
          <small>{{ customerDocument }}</small>
        </h3>
        <h4 v-if="titleHtmlTag == 'h4'">
          <strong>{{ customerName }}</strong>
          <small>{{ customerDocument }}</small>
        </h4>
      </span>
      <customer-menu v-if="includeCustomerMenu"
                     :customer-internal-id="customerInternalId"
                     :customer-entity-id="customerEntityId"
                     :show-customer-menu-profile="showCustomerMenuProfile"
                     :show-customer-menu-profile-rds-marketing="showCustomerMenuProfileRdsMarketing" />
    </span>
    <span class="d-block ui-tag-wrapper">
      <customer-badge-id :public-id="customerId" />
      <span class="ui-tag"
            :class="badge.class"
            data-toggle="popover"
            data-trigger="hover"
            data-placement="top"
            data-container="body"
            v-for="(badge, index) in customBadges"
            :key="index"
            :style="badge.style"
            :data-content="badge.description">
        <span>{{ badge.description }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import CustomerMenu from "domains/customers-and-subscriptions/components/customers/item/menu";
import CustomerBadgesId from "domains/customers-and-subscriptions/components/customers/badges/id";

export default {
  components: {
    "customer-menu": CustomerMenu,
    "customer-badge-id": CustomerBadgesId
  },
  props: {
    customerId: {
      default: "",
      type: String
    },
    customerName: {
      default: "",
      type: String
    },
    customerDocument: {
      default: "",
      type: String
    },
    customerInternalId: {
      default: null,
      type: Number
    },
    customerEntityId: {
      default: null,
      type: String
    },
    customBadges: {
      default: null,
      type: Array
    },
    includeCustomerMenu: {
      default: false,
      type: Boolean
    },
    titleHtmlTag: {
      default: "h2",
      type: String
    },
    showCustomerMenuProfile: {
      required: true,
      type: Boolean
    },
    showCustomerMenuProfileRdsMarketing: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".customers-item-title .popover-active[data-toggle=\"popover\"]").popover();
    } catch (error) {
      // console.log(error);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";

.customers-item-title {
  .customer-name-and-identification-document {
    width: 100%;

    h2 {
      font-size: $font-size-xxl;
      line-height: $line-height-xxl;
      color: $color-gray-100;
      margin-top: 0;
      margin-bottom: $spacing-md;
      display: block;
      small {
        font-size: $font-size-md;
        line-height: $line-height-md;
      }
    }
    h4 {
      font-size: $font-size-lg;
      line-height: $line-height-lg;
      color: $color-gray-100;
      margin-top: 0;
      margin-bottom: $spacing-sm;
      display: block;
    }
    small {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      font-weight: normal;
      color: $color-gray-80;
      display: block;
    }
  }
}
</style>
