<template>
  <span class="ui-tag ui-tag-id" :title="subscriptionIdLabel">{{ publicId }}</span>
</template>

<script>
import I18n from "core/i18n";

export default {
  props: {
    publicId: {
      default: "",
      type: String
    }
  },
  data: function () {
    const i18nScope = "components.customers-and-subscriptions.subscriptions.badges.id";
    const subscriptionIdLabel = I18n.t(`${i18nScope}.subscription-id`);

    return {
      subscriptionIdLabel: subscriptionIdLabel
    };
  }
};
</script>
