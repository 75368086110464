<template>
  <div class="row display-flex align-items-center">
    <div class="create-customer-success text-center">
      <div class="create-customer-success-box">
        <i class="fas fa-fw fa-check-circle"/>
        <p v-html="$t('components.customers-and-subscriptions.customers.actions.box-success.label', { CustomerName: CustomerName })" />
        <button type="button"
                @click="clickAddAnotherCustomer"
                class="ui-btn ui-btn-primary">{{ $t("components.customers-and-subscriptions.customers.actions.box-success.create-another-customer") }}</button>
      </div>
      <a href="/"
         class="ui-btn ui-btn-secondary">{{ $t("components.customers-and-subscriptions.customers.actions.box-success.back-to-home") }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    CustomerName: {
      default: "",
      type: String
    }
  },

  methods: {
    clickAddAnotherCustomer: function () {
      this.$parent.attemptSubmitStatus = null;
      this.clickClearFormAllFields();
      this.backToTopScreen();
    },

    clickClearFormAllFields () {
      Object.assign(this.$parent.$data, this.$parent.$options.data.call(this));
    },

    backToTopScreen () {
      window.scrollTo({
        "behavior": "smooth",
        "left": 0,
        "top": 0
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.create-customer-success {
  padding: $spacing-xxxl 0;
  width: 400px;
  margin: 0 auto;
  border-radius: 20px;

  .create-customer-success-box {
    i {
      font-size: 112px;
      color: $color-green-70;
    }

    p {
      color: $color-gray-60;
      font-size: $font-size-xl;
      margin: $spacing-xxl;
    }
  }
}
</style>
