<template>
  <div class="alert alert-danger text-center" role="alert">
    <small class="error-label"
           v-html="$t('components.customers-and-subscriptions.customers.actions.box-error.label', { CustomerName: CustomerName })" />
    <ul class="list-unstyled">
      <li v-for="error in errors" :key="error.type">
        <small class="error-description"
               v-html="$t('components.customers-and-subscriptions.customers.actions.box-error.error', {
                 field: $t(`text.errors.errorFields.${error.field}`),
                 type: $t(`text.errors.errorType.${error.type}`)
        })" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    CustomerName: {
      default: "",
      type: String
    },
    errors: {
      default () {
        return [];
      },
      type: Array
    }
  }
};
</script>
