<template>
  <div class="company-identification-logo-or-name">
    <div v-if="linkToCompanyLogo"
         class="company-identification-logo"
         :class="[logoSize]">
      <img :src="linkToCompanyLogo" :alt="companyName">
    </div>
    <div v-if="!linkToCompanyLogo"
         class="company-identification-name"
         :class="[logoSize, logoColor]">
      <span>{{ companyName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyName: {
      type: String,
      required: true
    },

    logoColor: {
      type: String,
      required: true
    },

    logoSize: {
      type: String,
      default: null
    },
  },

  computed: {
    linkToCompanyLogo () {
      try {
        const formatedCompanyName = this.companyName.toLowerCase().replace(/ /g, "-").replace(".", "-");
        const imageURL = require(`../../images/company/logo-${formatedCompanyName}-default.svg`);
        return imageURL;
      } catch (error) {
        if (error instanceof Error && error.toString().indexOf("Cannot find module") > -1) {
          return null;
        } else {
          throw error;
        }
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.company-identification-logo-or-name {
  .company-identification-logo {
    img {
      margin-bottom: $spacing-xs;
      max-width: 250px;
    }

    &.small {
      img {
        max-width: 150px;
      }
    }
  }

  .company-identification-name {
    color: $color-white;
    font-weight: bold;
    font-size: 26px;
    margin: 0;

    &.small {
      font-size: 18px;
    }

    &.color {
      color: $color-gray-80;
    }
  }
}

@media print {
  .company-identification-logo-or-name {
    .company-identification-logo {
      img {
        max-width: 250px !important;
      }
    }
  }
}
</style>
