<template>
  <i class="subscriptions-badges-status fas fa-info-circle fa-fw popover-active subscription-icon-status"
     :class="statusClass"
     data-container="body"
     data-toggle="popover"
     data-trigger="hover"
     data-placement="left"
     :data-content="popoverContent"
     :data-title="popoverTitle" />
</template>

<script>
export default {
  props: {
    status: {
      default: "",
      type: String
    },
    createdAt: {
      default: "",
      type: String
    },
    activatedAt: {
      default: "",
      type: String
    },
    canceledAt: {
      default: "",
      type: String
    },
    endedAt: {
      default: "",
      type: String
    }
  },
  data () {
    const popoverTitle = this.$t(`components.customers-and-subscriptions.subscriptions.badges.status.title.${this.status}`);
    let date;
    if (this.status === "trial") date = this.createdAt;
    if (this.status === "active") date = this.activatedAt;
    if (this.status === "canceled") date = this.canceledAt;
    if (this.status === "ended") date = this.endedAt;

    if (date) {
      const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
      date = new Date(date.replace(pattern, "$3-$2-$1"));
    }

    return {
      statusClass: this.status,
      popoverTitle: popoverTitle,
      popoverContent: this.$d(date, "default")
    };
  },

  mounted () {
    // Should be removed when using vue-bootstrap
    try {
      $(".subscriptions-badges-status[data-toggle=\"popover\"]").popover();
    } catch (error) {
      // console.log(error);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "~styles/framework/variables";

  .trial {
    color: $color-blue-60;
  }

  .active {
    color: $color-green-70;
  }

  .canceled {
    color: $color-red-70;
  }

  .ended {
    color: $color-gray-40;
  }
</style>
