export class Communication {
  constructor () {
    this.id = "";
    this.completed = false;
  }

  static get (id) {
    const obj = new Communication();
    obj.id = id;

    const value = localStorage.getItem("communications");
    if (!value) return obj;
    const jsonValue = JSON.parse(value);
    if (!jsonValue || !jsonValue[id]) return obj;

    // Set object attributes from locale storage
    obj.completed = jsonValue[id].completed;

    return obj;
  }

  save () {
    const value = localStorage.getItem("communications") || "{}";
    const jsonValue = JSON.parse(value);
    jsonValue[this.id] = { completed: this.completed };

    localStorage.setItem("communications", JSON.stringify(jsonValue));
  }
}
