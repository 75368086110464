import en from "./components/en";
import es from "./components/es";
import ptbr from "./components/pt-BR";

export default {
  en: {
    components: en
  },
  es: {
    components: es
  },
  "pt-BR": {
    components: ptbr
  }
};
