const Countries = [
  { value: "AF" },
  { value: "ZA" },
  { value: "AL" },
  { value: "DE" },
  { value: "AD" },
  { value: "AO" },
  { value: "AI" },
  { value: "AG" },
  { value: "SA" },
  { value: "DZ" },
  { value: "AR" },
  { value: "AM" },
  { value: "AW" },
  { value: "AU" },
  { value: "AT" },
  { value: "AZ" },
  { value: "BS" },
  { value: "BH" },
  { value: "BB" },
  { value: "BB" },
  { value: "BE" },
  { value: "BZ" },
  { value: "BJ" },
  { value: "BM" },
  { value: "BY" },
  { value: "BO" },
  { value: "BA" },
  { value: "BW" },
  { value: "BR" },
  { value: "BN" },
  { value: "BG" },
  { value: "BF" },
  { value: "BI" },
  { value: "BT" },
  { value: "CV" },
  { value: "KH" },
  { value: "CA" },
  { value: "QA" },
  { value: "KZ" },
  { value: "EA" },
  { value: "TD" },
  { value: "CL" },
  { value: "CN" },
  { value: "CY" },
  { value: "VA" },
  { value: "SG" },
  { value: "CO" },
  { value: "KM" },
  { value: "CG" },
  { value: "CD" },
  { value: "KP" },
  { value: "KR" },
  { value: "CI" },
  { value: "CR" },
  { value: "HR" },
  { value: "CU" },
  { value: "CW" },
  { value: "DG" },
  { value: "DK" },
  { value: "DJ" },
  { value: "DM" },
  { value: "EG" },
  { value: "SV" },
  { value: "AE" },
  { value: "EC" },
  { value: "ER" },
  { value: "SK" },
  { value: "SI" },
  { value: "ES" },
  { value: "US" },
  { value: "EE" },
  { value: "ET" },
  { value: "EZ" },
  { value: "FJ" },
  { value: "PH" },
  { value: "FI" },
  { value: "FR" },
  { value: "GA" },
  { value: "GM" },
  { value: "GH" },
  { value: "GE" },
  { value: "GI" },
  { value: "GD" },
  { value: "GR" },
  { value: "GL" },
  { value: "GP" },
  { value: "GU" },
  { value: "GT" },
  { value: "GG" },
  { value: "GY" },
  { value: "GF" },
  { value: "GN" },
  { value: "GQ" },
  { value: "GW" },
  { value: "HT" },
  { value: "NL" },
  { value: "HN" },
  { value: "BV" },
  { value: "HM" },
  { value: "HK" },
  { value: "HU" },
  { value: "YE" },
  { value: "CX" },
  { value: "AC" },
  { value: "IM" },
  { value: "NF" },
  { value: "AX" },
  { value: "IC" },
  { value: "KY" },
  { value: "CC" },
  { value: "CK" },
  { value: "FO" },
  { value: "GS" },
  { value: "FK" },
  { value: "MP" },
  { value: "MH" },
  { value: "UM" },
  { value: "PN" },
  { value: "SB" },
  { value: "TC" },
  { value: "VG" },
  { value: "VI" },
  { value: "IN" },
  { value: "ID" },
  { value: "IR" },
  { value: "IQ" },
  { value: "IE" },
  { value: "IS" },
  { value: "IL" },
  { value: "IT" },
  { value: "JM" },
  { value: "JP" },
  { value: "JE" },
  { value: "JO" },
  { value: "XK" },
  { value: "KW" },
  { value: "LA" },
  { value: "LS" },
  { value: "LV" },
  { value: "LB" },
  { value: "LR" },
  { value: "LY" },
  { value: "LI" },
  { value: "LT" },
  { value: "LU" },
  { value: "MO" },
  { value: "MK" },
  { value: "MG" },
  { value: "MY" },
  { value: "MW" },
  { value: "MV" },
  { value: "ML" },
  { value: "MT" },
  { value: "MA" },
  { value: "MQ" },
  { value: "MU" },
  { value: "MR" },
  { value: "YT" },
  { value: "MX" },
  { value: "MM" },
  { value: "FM" },
  { value: "MZ" },
  { value: "MD" },
  { value: "MC" },
  { value: "MN" },
  { value: "ME" },
  { value: "MS" },
  { value: "UN" },
  { value: "NA" },
  { value: "NR" },
  { value: "NP" },
  { value: "NI" },
  { value: "NE" },
  { value: "NG" },
  { value: "NU" },
  { value: "NO" },
  { value: "NC" },
  { value: "NZ" },
  { value: "OM" },
  { value: "BQ" },
  { value: "PW" },
  { value: "PA" },
  { value: "PG" },
  { value: "PK" },
  { value: "PY" },
  { value: "PE" },
  { value: "PF" },
  { value: "PL" },
  { value: "PR" },
  { value: "PT" },
  { value: "KE" },
  { value: "KG" },
  { value: "KI" },
  { value: "GB" },
  { value: "CF" },
  { value: "DO" },
  { value: "CM" },
  { value: "RE" },
  { value: "RO" },
  { value: "RW" },
  { value: "RU" },
  { value: "EH" },
  { value: "PM" },
  { value: "WS" },
  { value: "AS" },
  { value: "SM" },
  { value: "SH" },
  { value: "LC" },
  { value: "BL" },
  { value: "KN" },
  { value: "MF" },
  { value: "ST" },
  { value: "VC" },
  { value: "SC" },
  { value: "SN" },
  { value: "SL" },
  { value: "RS" },
  { value: "SX" },
  { value: "SY" },
  { value: "SO" },
  { value: "LK" },
  { value: "SZ" },
  { value: "SD" },
  { value: "SS" },
  { value: "SE" },
  { value: "CH" },
  { value: "SR" },
  { value: "SJ" },
  { value: "TH" },
  { value: "TW" },
  { value: "TJ" },
  { value: "TZ" },
  { value: "CZ" },
  { value: "IO" },
  { value: "TF" },
  { value: "PS" },
  { value: "TL" },
  { value: "TG" },
  { value: "TK" },
  { value: "TO" },
  { value: "TT" },
  { value: "TA" },
  { value: "TN" },
  { value: "TM" },
  { value: "TR" },
  { value: "TV" },
  { value: "UA" },
  { value: "UG" },
  { value: "UY" },
  { value: "UZ" },
  { value: "VU" },
  { value: "VE" },
  { value: "VN" },
  { value: "WF" },
  { value: "ZM" },
  { value: "ZW" }];

export default Countries;
