import Vue from "vue";
import I18n from "core/i18n";
import Store from "core/store";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

const components = [
  "customers/actions/new",
  "customers/badges/id",
  "customers/item/menu",
  "customers/item/title",
  "subscriptions/btn-open-details-sidebar",
  "subscriptions/details-sidebar",
  "subscriptions/badges/amount",
  "subscriptions/badges/blocked-pastdue",
  "subscriptions/badges/discount",
  "subscriptions/badges/expiration-day",
  "subscriptions/badges/id",
  "subscriptions/badges/payment-method",
  "subscriptions/badges/status",
  "subscriptions/badges/upgrade-invoice",
  "subscriptions/item/title"
];

document.addEventListener("DOMContentLoaded", () => {
  components.forEach(function (component) {
    const tagName = `customers-and-subscriptions-${component.replace(/\//g, "-")}`;
    const elements = document.getElementsByTagName(tagName);

    // Do not initialize if element was not rendered
    if (elements.length) {
      var elementsList = Array.prototype.slice.call(elements);

      elementsList.forEach(function (element) {
        const data = element.getAttribute("data");
        const importedComponent = require(`./components/${component}`);

        // Get props from server side rendered data attribute
        let props = {};
        if (data) props = JSON.parse(data);

        new Vue({
          i18n: I18n,
          store: Store,
          el: element,
          render: h => h(importedComponent.default, { props })
        });
      });
    }
  });
});
