<template>
  <span class="ui-tag ui-tag-id" :title="customerIdLabel">
    {{ publicId }}
  </span>
</template>

<script>
import I18n from "core/i18n";

export default {
  props: {
    publicId: {
      default: "",
      type: String
    }
  },
  data: function () {
    const i18nScope = "components.customers-and-subscriptions.customers.badges.id";
    const customerIdLabel = I18n.t(`${i18nScope}.customer-id`);

    return {
      customerIdLabel: customerIdLabel
    };
  }
};
</script>
