export default {
  payment: {
    methods: {
      cash: "Cash",
      check: "Check",
      boleto: "Brazilian boleto",
      "bank-transfer": "Bank transfer",
      "credit-card": "Credit card",
      paypal: "Paypal",
      moip: "Moip",
      dda: "DDA",
      oxxo: "OXXO",
      baloto: "Baloto",
      others: "Others"
    }
  }
};
