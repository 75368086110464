<template>
  <div :class="{ active: isOpen }" class="details-sidebar">
    <div class="details-sidebar-content"
         v-if="isOpen && parentSubscription && customer">
      <a class="btn btn-link pull-right close-btn"
         role="button"
         @click="close">
        <i class="fas fa-times" />
      </a>
      <h3 v-if="customer">{{ customer.name }}</h3>
      <hr>
      <div class="customers-and-subscriptions-subscriptions-item">
        <div class="subscription subscription-home">
          <div class="subscription-item subscription-active">
            <div class="col-xs-12 subscription-name-labels">
              <label class="d-block">

                <subscriptions-badges-status
                  :status="parentSubscription.status"
                  :created-at="parentSubscription.createdAt"
                  :activated-at="parentSubscription.activatedAt"
                  :canceled-at="parentSubscription.canceledAt"
                  :ended-at="parentSubscription.endedAt" />

                <subscriptions-item-title :id="parentSubscription.id"
                                          :product-name="parentSubscription.productName"
                                          :name="parentSubscription.name"
                                          :site="parentSubscription.site" />

              </label>

              <span class="ui-tag-wrapper">
                <customers-and-subscriptions-subscriptions-badges-id :public-id="parentSubscription.id" />

                <customers-and-subscriptions-subscriptions-badges-amount
                  :sale-item-price="parentSubscription.saleItemPrice"
                  :readjusted-amount="parentSubscription.readjustedAmount"
                  :amount-considering-coupon="parentSubscription.amountConsideringCoupon"
                  :product-type="parentSubscription.productType"
                  :currency="parentSubscription.currency" />

                <customers-and-subscriptions-subscriptions-badges-discount
                  v-if="parentSubscription.coupon"
                  :kind="parentSubscription.coupon.kind"
                  :amount="parentSubscription.coupon.amount"
                  :billing-unit="parentSubscription.coupon.billingUnit"
                  :billing-quantity-type="parentSubscription.coupon.billingQuantityType"
                  :billing-quantity-total="parentSubscription.coupon.billingQuantityTotal"
                  :billing-quantity-used="parentSubscription.coupon.billingQuantityUsed"
                  :currency="parentSubscription.currency" />

                <customers-and-subscriptions-subscriptions-badges-payment-method
                  :payment-method="parentSubscription.paymentMethod" />

                <customers-and-subscriptions-subscriptions-badges-blocked-pastdue
                  :blocked="parentSubscription.blocked"
                  :past-due="parentSubscription.pastDue" />

              </span>
            </div>
          </div>

          <addons :customer-id="customer.id" :parent-subscription-id="parentSubscription.id" />

        </div>
      </div>
      <a :href="viewAllSubscriptionsLink" class="ui-link d-block text-center btn-view-all-subscriptions">
        {{ $t("components.customers-and-subscriptions.subscriptions.details-sidebar.view-all-subscriptions") }}
      </a>
    </div>
  </div>
</template>

<script>
import ComponentSubscriptionsItemTitle from "domains/customers-and-subscriptions/components/subscriptions/item/title";
import ComponentSubscriptionsBadgesStatus from "domains/customers-and-subscriptions/components/subscriptions/badges/status";
import ComponentSubscriptionsBadgesId from "domains/customers-and-subscriptions/components/subscriptions/badges/id";
import ComponentSubscriptionsBadgesAmount from "domains/customers-and-subscriptions/components/subscriptions/badges/amount";
import ComponentSubscriptionsBadgesDiscount from "domains/customers-and-subscriptions/components/subscriptions/badges/discount";
import ComponentSubscriptionsBadgesPaymentMethod from "domains/customers-and-subscriptions/components/subscriptions/badges/payment-method";
import ComponentSubscriptionsBadgesBlockedPastDue from "domains/customers-and-subscriptions/components/subscriptions/badges/blocked-pastdue";
import ComponentAddons from "domains/customers-and-subscriptions/components/subscriptions/details-sidebar/addons";
import Routes from "core/legacy-rails-routes.js";

export default {
  created () {
    const self = this;
    window.addEventListener("keyup", function (e) {
      if (e.keyCode === 27) {
        self.close();
      }
    });
  },
  computed: {
    isOpen () {
      return this.$store.state.customersAndSubscriptions.subscriptionsDetailsSidebar.isOpen;
    },
    customer () {
      const obj = this.$store.state.customersAndSubscriptions.subscriptionsDetailsSidebar.customer;
      if (!obj) return null;
      return {
        id: obj.id,
        name: obj.name,
        internal_id: obj.internal_id
      };
    },
    parentSubscription () {
      const obj = this.$store.state.customersAndSubscriptions.subscriptionsDetailsSidebar.parentSubscription;
      if (!obj) return null;

      return obj;
    },
    viewAllSubscriptionsLink () {
      // FIXME: User public id instead internal when route supports it
      const customer = this.$store.state.customersAndSubscriptions.subscriptionsDetailsSidebar.customer;
      if (!customer) return;
      return Routes.paths.customers.subscriptions.index(customer.internal_id);
    }
  },
  methods: {
    close () {
      return this.$store.commit("customersAndSubscriptions/subscriptionsDetailsSidebar/close");
    }
  },

  components: {
    addons: ComponentAddons,
    "subscriptions-item-title": ComponentSubscriptionsItemTitle,
    "subscriptions-badges-status": ComponentSubscriptionsBadgesStatus,
    "customers-and-subscriptions-subscriptions-badges-id": ComponentSubscriptionsBadgesId,
    "customers-and-subscriptions-subscriptions-badges-amount": ComponentSubscriptionsBadgesAmount,
    "customers-and-subscriptions-subscriptions-badges-discount": ComponentSubscriptionsBadgesDiscount,
    "customers-and-subscriptions-subscriptions-badges-payment-method": ComponentSubscriptionsBadgesPaymentMethod,
    "customers-and-subscriptions-subscriptions-badges-blocked-pastdue": ComponentSubscriptionsBadgesBlockedPastDue
  }
};
</script>

<style scoped lang="scss">
  @import "~styles/framework/variables";

  .details-sidebar {
    position: fixed;
    top: 0;
    right: -500px;
    width: 500px;
    height: 100%;
    overflow-x: auto;
    background-color: $color-white;
    z-index: 1040;
    padding: 0;
    opacity: 0;
    -webkit-transition: 0.1s ease-in-out;
    -moz-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;

    &.active {
      right: 0;
      opacity: 1;
    }

    .details-sidebar-content {
      height: 100%;
      padding: $spacing-lg;
    }

    .subscription-item-addon {
      margin: 0;
    }
  }
</style>
